import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PaymentItem2 from "./PaymentItem2";

import { MdAddCircle as AddIcon } from "react-icons/md";
import styles from "./PaymentItems2.module.scss";
import { Grid } from "@mui/material";

class PaymentItems2 extends Component {
  handleDragEnd = (result) => {
    if (!result.destination) return;

    // helper function to reorder result (src: react-beautiful-dnd docs)
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // perform reorder
    const lineItems = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    // call parent handler with new state representation
    this.props.reorderHandler(lineItems);
  };

  render = () => {
    const { items, addHandler, reorderHandler, ...functions } = this.props;

    return (
      <form>
        <div
          id="payItem"
          className={`${styles.lineItems} d-flex justify-content-between`}
        >
          {/* <div className="card">
                        <div className="card-body overflow-scroll p-0"> */}
          <Grid container>
            <Grid item xs={8} className="mb-2">
              <h5 style={{ fontFamily: "Solway" }}>PAYMENTS</h5>
            </Grid>
            <Grid
              id="add_item"
              onClick={addHandler}
              item
              xs={4}
              className="d-flex justify-content-end mb-2"
            >
              <AddIcon size="2.25em" className={styles.addIcon} />
            </Grid>

            <Grid item xs={12}>
              <TableContainer
                component={({ children }) => (
                  <Paper
                    style={{
                      background: "rgba(255, 255, 255, 0.37)",
                    }}
                  >
                    {children}
                  </Paper>
                )}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className={`${styles.row} ${styles.header}`}>
                    <TableRow>
                      {/* <TableCell>#</TableCell> */}
                      <TableCell>PAYMENT MODE</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                      <TableCell>AMOUNT</TableCell>
                      <TableCell>DATE</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.items.map((item, i) => (
                      <TableRow key={i}>
                        <PaymentItem2
                          style={{ color: "red" }}
                          key={i + item.id}
                          index={i}
                          paymentMode={item.paymentMode}
                          amount={item.amount}
                          paymentDate={item.paymentDate}
                          paymentTypeId={item.paymentTypeId}
                          description={item.description}
                          {...functions}
                        />
                      </TableRow>
                    ))}

                    {/* </div>
                    </div> */}

                    {/* <div className={styles.addItem}>
                        <button id="pay_add_item" type="button" onClick={addHandler}><AddIcon size="1.25em" className={styles.addIcon} /> Add Item</button>
                    </div> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };
}

export default PaymentItems2;

PaymentItems2.propTypes = {
  items: PropTypes.array.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  focusHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  reorderHandler: PropTypes.func.isRequired,
};
