export const goldPurityChart = [
  {
    partsGold: "7/12",
    karat: 14,
    hallmark: 583,
    percentage: 58.3,
  },
  {
    partsGold: "6/24",
    karat: 6,
    hallmark: 250,
    percentage: 25,
  },
  {
    partsGold: "8/24",
    karat: 8,
    hallmark: 333,
    percentage: 33.3,
  },
  {
    partsGold: "9/24",
    karat: 9,
    hallmark: 375,
    percentage: 37.5,
  },
  {
    partsGold: "10/24",
    karat: 10,
    hallmark: 417,
    percentage: 41.7,
  },
  {
    partsGold: "12/24",
    karat: 12,
    hallmark: 500,
    percentage: 50,
  },
  {
    partsGold: "5/8",
    karat: 15,
    hallmark: 625,
    percentage: 62.5,
  },
  {
    partsGold: "3/4",
    karat: 18,
    hallmark: 750,
    percentage: 75,
  },
  {
    partsGold: "5/6",
    karat: 20,
    hallmark: 833,
    percentage: 83.3,
  },
  {
    partsGold: "7/8",
    karat: 21,
    hallmark: 875,
    percentage: 87.5,
  },
  {
    partsGold: "11/12",
    karat: 22,
    hallmark: 916,
    percentage: 91.6,
  },
  {
    partsGold: "24/24",
    karat: 24,
    hallmark: 999,
    percentage: 99.9,
  },
];
