import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import LineItem2 from "./LineItem2";

import { MdAddCircle as AddIcon } from "react-icons/md";
import styles from "./LineItems2.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

class LineItems2 extends Component {
  handleDragEnd = (result) => {
    if (!result.destination) return;

    // helper function to reorder result (src: react-beautiful-dnd docs)
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // perform reorder
    const lineItems = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    // call parent handler with new state representation
    this.props.reorderHandler(lineItems);
  };

  render = () => {
    const {
      items,
      addHandler,
      reorderHandler,
      goldPurityType,
      handlePurityTypeChange,
      oldDataGroup,
      ...functions
    } = this.props;

    const hideLaborAndPurity = oldDataGroup === 1;

    return (
      <form>
        <Grid container>
          <Grid item xs={8}>
            <h5 style={{ fontFamily: "Solway" }}>PURCHASE</h5>
          </Grid>
          <Grid
            id="add_item"
            onClick={addHandler}
            item
            xs={4}
            className="d-flex justify-content-end"
          >
            <AddIcon size="2.25em" className={styles.addIcon} />
          </Grid>
          <Grid item container justify="flex-end">
            <Grid item>
              {!hideLaborAndPurity && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Purity Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="goldPurityType"
                    name="row-radio-buttons-group"
                    onChange={handlePurityTypeChange}
                    value={goldPurityType}
                  >
                    <FormControlLabel
                      value="karat"
                      control={<Radio />}
                      label="Karat"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Percentage"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            {/* <button id="add_item" type="button" onClick={addHandler}><AddIcon size="1.25em" className={styles.addIcon} /> Add Item</button> */}
          </Grid>
        </Grid>

        <div
          id="lineItem"
          className={`${styles.lineItems} d-flex justify-content-between`}
        >
          {/* <div className="card">
                        <div className="card-body overflow-scroll p-0"> */}

          <Grid container>
            <Grid item xs={12}>
              <TableContainer
                component={({ children }) => (
                  <Paper
                    style={{
                      background: "rgba(255, 255, 255, 0.37)",
                    }}
                  >
                    {children}
                  </Paper>
                )}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className={`${styles.row} ${styles.header}`}>
                    <TableRow>
                      {/* <TableCell>#</TableCell> */}
                      <TableCell>ITEM</TableCell>

                      <TableCell>GOLD WT (g)</TableCell>

                      {!hideLaborAndPurity && (
                        <>
                          <TableCell>{`PURITY ${
                            goldPurityType === "karat" ? " (karat)" : " (%) "
                          }`}</TableCell>

                          <TableCell>LABOR</TableCell>
                        </>
                      )}
                      <TableCell>Rate</TableCell>

                      <TableCell>ITEM TOTAL</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.items.map((item, i) => (
                      <TableRow key={i}>
                        <LineItem2
                          style={{ color: "red" }}
                          goldPurityType={goldPurityType}
                          key={i + item.id}
                          index={i}
                          name={item.name}
                          description={item.description}
                          quantity={item.quantity}
                          price={item.price}
                          itemId={item.itemId}
                          purity={item.purity}
                          labor={item.labor}
                          hideLaborAndPurity={hideLaborAndPurity}
                          goldRate={this.props.goldRate}
                          amount={item?.amount}
                          {...functions}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {/* </div>

                    </div> */}
        </div>
      </form>
    );
  };
}

export default LineItems2;

LineItems2.propTypes = {
  items: PropTypes.array.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  focusHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  reorderHandler: PropTypes.func.isRequired,
};
