import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Modal } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
//alert
import { useAlert } from "react-alert";
// date range
import { DateRangePicker } from "react-date-range";
// import { BartChart, DoughnutChart } from '../../../components/ChartsMain';
import { Helmet } from "react-helmet-async";
// import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import axios from "../../../axios-util/customAxis";
import CheckoutForm from "../../components/CheckoutForm";
import TranslucentCard from "../../components/MaterialUI/Card/TranslucentCard";
import styles from "./ReportDashboard.module.scss";
import Masonry from "@mui/lab/Masonry";
import moment from "moment-timezone";
import { formatCash } from "../../../globalData/helpers";

const lineData = [
  {
    name: "Monday",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Monday",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Tuesday",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Wednesday",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Thursday",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Friday",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Saturday",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];

const PIE_CHART_COLORS = [
  "rgba(131, 210, 149, 0.8)",
  "rgba(222, 108, 108, 0.8)",
];
// This is your test publishable API key.
const stripePromise = loadStripe(
  `pk_test_51K7JmkSDxEmSu9AmbVgDwKl4M8pWdhWrTPz63KCM4S8xhkOVUG4gntryOfZ6Ipck6FT0G8F2y1eShDD9kERlfyz400v8c6ZqIF`
);

function ReportDashboard(props) {
  const alert = useAlert();

  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [customerNameSelected, setCustomerNameSelected] = useState("");
  const [noData, setNoData] = useState(false);

  // bar chart
  const [label, setLabel] = useState([]);
  const [totalSales, setTotalSales] = useState([]);
  const [totalPaid, setTotalPaid] = useState([]);
  const [totalDue, setTotalDue] = useState([]);
  const [reportOf, setReportOf] = useState("all customer");
  const [customerHistory, setCustomerHistory] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [customerNameSelectedName, setCustomerNameSelectedName] = useState("");
  const [showReportOf, setShowReportOf] = useState(false);
  const [show, setShow] = useState(false);
  const [graphData, setGraphData] = useState({});

  const [isSubscription, setIsSubscription] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  // stripe
  const [clientSecret, setClientSecret] = useState("");

  // date range
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const loadStripeS = async () => {
    let userData = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/users/me`
    );
    // console.log(userData.data);
    let data = {
      userId: userData.data._id,
    };

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/stripe/create-payment-intent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const loadGraphData = (customer = {}, dateRange = []) => {
    let APIURL = `${process.env.REACT_APP_API_ENDPOINT}/api/purchase/getGraphData`;
    axios
      .post(APIURL, { customer: customer, dateRange: dateRange[0] || {} })
      .then((resp) => {
        setGraphData((prev) => ({ ...prev, lineChartData: resp?.data }));
      })
      .catch((err) => {
        console.error(err);
        // alert("Could not found data");
        alert.error("Could not found data");
      });
  };

  const loadData = (customer, dateRange) => {
    setLoading(true);

    let APIURL = "";

    if (customer?._id) {
      APIURL = `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/mainreport?customerId=${customer._id}`;

      // setReportOf(customerNane);
      setSelectedCustomerId(`${customer}`);
      setCustomerHistory(true);

      if (dateRange?.[0]?.startDate && dateRange?.[0]?.endDate) {
        APIURL = `${
          process.env.REACT_APP_API_ENDPOINT
        }/api/invoice/mainreport?customerId=${
          customer?._id
        }&fromDate=${dateRange[0].startDate?.toISOString()}&toDate=${dateRange[0].endDate?.toISOString()}`;
      }

      if (customer === "allReport") {
        APIURL = `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/mainreport`;
      }
    } else if (dateRange?.[0]?.startDate && dateRange?.[0]?.endDate) {
      APIURL = `${
        process.env.REACT_APP_API_ENDPOINT
      }/api/invoice/mainreport?fromDate=${dateRange[0].startDate?.toISOString()}&toDate=${dateRange[0].endDate?.toISOString()}`;
    } else {
      APIURL = `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/mainreport`;
    }

    axios
      .get(APIURL)
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.message === "no data found") {
          setLoading(false);
          setNoData(true);
          // alert("not data found")
        } else {
          setMainData(resp.data);
          setGraphData((prev) => ({
            ...prev,
            pieChartData: [
              { name: "Sales", value: Number(resp?.data?.totalPaid || 0) },
              { name: "Due", value: Number(resp?.data?.totalDue || 0) },
            ],
          }));
          // console.log(resp.data);

          setLoading(false);
          setNoData(false);
          setShowReportOf(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        // alert("Could not found data");
        alert.error("Could not found data");
      });
  };

  const getCustomers = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`)
      .then((resp) => {
        resp = resp?.data || [];

        setCustomerData(resp);
      })
      .catch((err) => {});
  };

  const onCustomerSelect = (e) => {
    // console.log(e.id);
    setCustomerNameSelected(e?._id);
    setCustomerNameSelectedName(e?.customerName);
    setSelectedCustomer(e);
    loadData(e, dateRange);
    loadGraphData(e, dateRange);
  };
  const filterClick = () => {
    loadData(customerNameSelected, dateRange);
    loadGraphData(selectedCustomer, dateRange);
  };

  const handleCloseModal = () => {
    setShow(false);
    loadData(customerNameSelected, dateRange);
    loadGraphData(selectedCustomer, dateRange);
  };

  const handleCloseModalSubsBox = () => {
    // setIsSubscription(false);
  };

  const handleCloseModalPayment = () => {
    // setOpenPayment(false);
  };

  const openDateFilterMOdal = () => {
    setShow(true);
  };

  const loadPaymentPage = () => {
    loadStripeS();
    setIsSubscription(false);
    setOpenPayment(true);
  };

  useEffect(() => {
    loadData();
    getCustomers();
    loadGraphData();
    // loadStripeS();
  }, []);

  if (loading) {
    return <h6>Loading...</h6>;
  }

  return (
    <>
      <Helmet>
        <title>Invoice App - Dashboard</title>
      </Helmet>

      <TranslucentCard className={styles.w_card} test-id="dashboard">
        <Masonry columns={{ xs: 2 }} spacing={1} className="w-100">
          <TranslucentCard
            className="d-flex align-items-center "
            style={{ height: 90, width: "65%" }}
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="basic-single w-50"
              options={customerData}
              isOptionEqualToValue={(o, v) => o._id === v._id}
              getOptionLabel={(o) => o.customerName || ""}
              renderInput={(params) => (
                <TextField {...params} label="Filter By  Customer" />
              )}
              value={selectedCustomer}
              onChange={(e, v) => onCustomerSelect(v)}
            />
            {/* <Select
              style={{ zIndex: 10 }}
              className="basic-single w-50"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              name="paymentMode"
              placeholder="Filter By Customer"
              options={customerData}
              getOptionLabel={(o) => o.customerName}
              getOptionValue={(o) => ({
                label: `${o.customerName}`,
                value: `${o._id}`,
              })}
              value={[selectedCustomer]}
              onChange={(e) => onCustomerSelect(e)}
            /> */}

            {/* <button
              className="btn btn-sm btn-outline-primary xs-w-100 w-25 mx-1"
              onClick={() => filterClick()}
            >
              Search
            </button> */}
            <TextField
              size="small"
              className="w-50 mx-1"
              value={
                dateRange?.[0]?.startDate
                  ? `${moment(dateRange?.[0]?.startDate).format(
                      "DD-MMM-YYYY"
                    )} - ${moment(dateRange?.[0]?.endDate).format(
                      "DD-MMM-YYYY"
                    )}`
                  : ""
              }
              onFocus={() => openDateFilterMOdal()}
              label="Filter By Date"
            />
          </TranslucentCard>

          <TranslucentCard className="w-25" style={{ height: 270 }}>
            <ResponsiveContainer width="100%" height={"100%"}>
              <PieChart width={400} height={400}>
                <Pie
                  data={graphData.pieChartData || []}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {graphData?.pieChartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="bottom" align="left" />
              </PieChart>
            </ResponsiveContainer>
          </TranslucentCard>

          <TranslucentCard className="w-75" style={{ height: 270 }}>
            <Grid
              container
              justifyContent="center"
              spacing={1}
              className="w-100"
            >
              <Grid item xs={12} sm={6}>
                {/* <Paper sx={{ width: "100%", height: 110 }}> */}
                <Item
                  label={"Total Invoice"}
                  value={
                    noData
                      ? 0
                      : mainData?.invoiceCount === "NaN"
                      ? 0
                      : mainData?.invoiceCount
                  }
                />

                {/* </Paper> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item
                  label={"Total Sale"}
                  value={`$${
                    noData
                      ? 0
                      : mainData?.totalAmount === "NaN"
                      ? 0
                      : formatCash(Number(mainData?.totalAmount))
                  }`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item
                  label={"Total Paid"}
                  value={`$${
                    noData
                      ? 0
                      : mainData?.totalPaid === "NaN"
                      ? 0
                      : formatCash(Number(mainData?.totalPaid))
                  }`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item
                  label={"Total Due"}
                  value={`$${
                    noData
                      ? 0
                      : mainData?.totalDue === "NaN"
                      ? 0
                      : formatCash(
                          Number(parseFloat(mainData?.totalDue).toFixed(2))
                        )
                  }`}
                />
              </Grid>
            </Grid>
          </TranslucentCard>
          <TranslucentCard className="w-25 " style={{ height: 370 }}>
            <div className="d-flex" style={{ height: "10%" }}>
              <div className="w-25 text-center"></div>
              <div className="w-50  text-center h5">Customers</div>
              <div className="w-25 text-center h5">Due</div>
            </div>
            <hr className="m-0" />
            <div className="overflow-auto" style={{ height: "90%" }}>
              {customerData.map((customer, index) => (
                <div className="d-flex my-1" key={index}>
                  <div className="w-25"></div>
                  <div className="w-50 text-center">
                    {customer?.customerName}
                  </div>
                  <div className="w-25 text-center">
                    {(mainData?.customerDues || []).find(
                      (cus) => cus?.customerId === customer?._id
                    )?.totalDue || 0}
                  </div>
                </div>
              ))}
            </div>
            <hr />
          </TranslucentCard>

          <TranslucentCard className="w-75" style={{ height: 270 }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={graphData?.lineChartData || []}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" style={{ visibility: "hidden" }} />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend layout="horizontal" verticalAlign="top" align="left" />
                <Line
                  type="monotone"
                  dataKey="Sales"
                  stroke="#23B543"
                  activeDot={{ r: 8 }}
                  strokeWidth={5}
                />
                {/* <Line
                  type="monotone"
                  dataKey="uv"
                  stroke="#23B543"
                  strokeWidth={3}
                /> */}
              </LineChart>
            </ResponsiveContainer>
          </TranslucentCard>
        </Masonry>
      </TranslucentCard>

      <Modal show={show} size="xl" onHide={handleCloseModal}>
        <Modal.Body>
          <div className="">
            <DateRangePicker
              onChange={(item) => {
                setDateRange([item.selection]);
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isSubscription} size="sm" onHide={handleCloseModalSubsBox}>
        <Modal.Body>
          <div className="text-center">
            <div>
              <span className="text-danger text-center mb-2">
                {" "}
                Your subscription is ended!{" "}
              </span>
              <hr />
            </div>
            <button
              onClick={() => loadPaymentPage()}
              className="btn btn-sm btn-primary"
            >
              Subscribe
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={openPayment} size="sm" onHide={handleCloseModalPayment}>
        <Modal.Body>
          <div className="">
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Item = ({ label, value }) => {
  return (
    <Grid
      className="border rounded mb-2 w-100 bg-white"
      alignItems={"center"}
      item
      container
      style={{ height: 100, padding: 0 }}
    >
      <Grid item xs={12} sm={4} className="h6 ps-2">
        {label}
      </Grid>

      <Grid item xs={12} sm={8} className={`h2 ${styles.footer_text}`}>
        {value}
      </Grid>
    </Grid>
  );
};

export default ReportDashboard;
