import { Box, Typography } from "@mui/material";
import { Button, Card, Container, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as MySvg } from "../JewelPay.svg";

const HomePageContainer = (props) => {
  const history = useHistory();
  return (
    <main>
      <section className="d-flex align-items-center justify-content-center mt-2">
        <Container className="d-block justify-content-center align-items-center">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  backgroundColor: "rgba(255, 255, 255, 0.31)",
                }}
                style={{
                  width: 525,
                  height: 60,
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/about")}
                >
                  ABOUT
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/pricing")}
                >
                  PRICING
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/adm-reg")}
                >
                  SIGN UP
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/")}
                >
                  LOG IN
                </Button>
              </Box>
            </div>
            <Box
              sx={{
                width: "45vw",
                // height: "90vh",
                backgroundColor: "rgba(255, 255, 255, 0.28)",
                display: "block",
                marginTop: "2px",
                paddingTop: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "3rem",
                border: 2,
                borderColor: "#ffffff",
                borderRadius: 5,
              }}
              className="justify-content-center align-items-center"
            >
              <Card
                className="justify-content-center align-items-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.37)",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  // height: 600,
                }}
              >
                <div className="">
                  <Box
                    sx={{
                      display: "grid",
                      backgroundColor: "rgba(255, 255, 255, 0.37)",
                      borderRadius: 5,
                      marginTop: "1rem",
                      marginBottom: 2,
                      height: 80,
                      width: 300,
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <MySvg />
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <Typography
                          className="d-flex justify-content-center align-items-center pt-3 "
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Jewel Pay
                        </Typography>
                      </div>
                    </div>
                  </Box>
                </div>
                {props.children}
              </Card>
            </Box>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default HomePageContainer;
