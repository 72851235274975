import { Tooltip } from "@mui/material";
import React from "react";

const MyToolTip = ({ title, children, ...props }) => {
  return (
    <Tooltip {...props} title={title}>
      {children}
    </Tooltip>
  );
};

export default MyToolTip;
