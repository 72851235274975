import React, { useEffect, useRef } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Figure,
  Form,
  Button,
  InputGroup,
  Card,
} from "@themesberg/react-bootstrap";
import {
  faBuilding,
  faEnvelope,
  faUser,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "../../axios-util/customAxis";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import TranslucentCard from "../components/MaterialUI/Card/TranslucentCard";
//alert
import { useAlert } from "react-alert";

export default () => {
  const alert = useAlert();
  const uploadRef = useRef(null);
  const [key, setKey] = useState("logo");
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    address: {
      companyName: "",
      street: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      telephone: "",
    },
    invoiceSetting: {
      invoiceStartSeries: "",
      invoicePrefix: "",
      // goldRateUpdates: {
      //   openingPrice: true,
      // },
    },
  });
  const [logo, setLogo] = useState({
    myLogo: "https://via.placeholder.com/265x100",
  });

  const [selectedFile, setSelectedFile] = useState({
    selectedFile: "",
  });

  // const [invoiceSettingS, setInvoiceSettingS] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`)
      .then((resp) => {
        setLogo({ ...logo, ["myLogo"]: resp.data.profilePic });
        if (!resp.data?.address) {
          resp.data.address = {
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            telephone: "",
          };
        }
        setInputField({ ...resp.data });
      })
      .catch((err) => {});
  }, []);

  // const handleFileChange = (e) => {
  //   setSelectedFile({ ...selectedFile, ["selectedFile"]: e.target.files[0] });
  // };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Create an object of formData
    // const formData = new FormData();

    // // Update the formData object
    // formData.append("file", selectedFile.selectedFile);

    var file = e.target.files[0];
    var imageType = /image.*/;

    if (!file?.type?.match(imageType))
      return alert.error("File type not supported!");

    var form_data = new FormData();
    form_data.append("file", file);

    // Request made to the backend api
    // Send formData object
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/me/logo`,
        form_data
      );
      alert.success("Logo updated successfully");
      setLogo({ ...logo, ["myLogo"]: resp.data.profilePic });
    } catch (err) {
      console.log(err);
      alert.error("Could not upload the logo");
    }
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`, inputField)
      .then((resp) => {
        // alert("Updated profile successfully");
        alert.success("Updated profile successfully");
      })
      .catch((err) => {
        // alert("Could not update profile");
        alert.error("Could not update profile");
      });
  };
  const handleInput = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  const handleNestedInput = (e) => {
    setInputField({
      ...inputField,
      address: { ...inputField.address, [e.target.name]: e.target.value },
    });
  };

  const Logo = () => {
    return (
      <Row>
        <Col sm={12} md={6}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload invoice logo(265x100)</Form.Label>
            <input
              accept={".png,.jpg"}
              id="icon-button-photo"
              ref={uploadRef}
              // multiple={props.multiple}
              style={{ display: "none", cursor: "hand" }}
              onChange={handleFormSubmit}
              onClick={(event) => (event.target.value = null)}
              type="file"
              // disabled={props.disabled}
            />
            {/* <Form.Control
                accept={".png,.jpg"}
                onChange={handleFileChange}
                type="file"
                // value={selectedFile}
              /> */}
          </Form.Group>
          <Button onClick={() => uploadRef.current.click()} variant="primary">
            Upload
          </Button>
        </Col>
        <Col sm={12} md={6}>
          <Figure>
            <Figure.Image
              width={265}
              height={100}
              alt="265x100"
              src={`${logo.myLogo}`}
            />

            <Figure.Caption>Invoice logo(265x100)</Figure.Caption>
          </Figure>
        </Col>
      </Row>
    );
  };
  const ChangePwd = () => {
    const history = useHistory();
    const [changePwdData, setNewPassword] = useState({
      currentPassword: "",
      newPassword: "",
      cNewPassword: "",
    });
    const handleFormChange = (e) => {
      setNewPassword({ ...changePwdData, [e.target.name]: e.target.value });
    };
    const handleChangePassword = (e) => {
      e.preventDefault();
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/api/users/me/changepassword`,
          changePwdData
        )
        .then((resp) => {
          alert("Password changed successfully, please login again");
          localStorage.clear();
          history.push("/");
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    };
    return (
      <Row>
        <Col md={6}>
          <Form onSubmit={handleChangePassword}>
            <Form.Group className="mb-3" controlId="currentPwd">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                name="currentPassword"
                onChange={handleFormChange}
                type="password"
                placeholder="Current Password"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPwd">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                name="newPassword"
                onChange={handleFormChange}
                type="password"
                placeholder="New Password"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="confrmnewPwd">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                name="cNewPassword"
                onChange={handleFormChange}
                type="password"
                placeholder="Confirm New Password"
              />
            </Form.Group>
            {changePwdData.cNewPassword != changePwdData.newPassword && (
              <Form.Label>Password does not match!</Form.Label>
            )}
            {changePwdData.cNewPassword == changePwdData.newPassword && (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
          </Form>
        </Col>
      </Row>
    );
  };

  const handleFormChangeInvoiceSetting = (event) => {
    const name = event.target.name,
      checked = event.target.checked,
      value = event.target.value;
    let field = { ...inputField };

    if (name === "live" || name === "openingPrice") {
      field = {
        ...field,
        invoiceSetting: {
          ...field?.invoiceSetting,
          goldRateUpdates: { [name]: checked },
        },
      };
    } else {
      field = {
        ...field,
        invoiceSetting: {
          ...field?.invoiceSetting,
          [name]: value,
        },
      };
    }

    setInputField(field);
  };

  const handleInvoiceSetting = async (e) => {
    e.preventDefault();
    // let invoiceSetting = {
    //     invoiceSetting: {
    //         ...invoiceSettingS,
    //     }
    // }

    // console.log(invoiceSetting);
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`, inputField)
      .then((resp) => {
        // alert("Updated profile successfully");
        alert.success("Updated profile successfully");
      })
      .catch((err) => {
        // alert("Could not update profile");
        alert.error("Could not update profile");
      });
  };

  return (
    <>
      <Helmet>
        <title>Invoice App - Account</title>
      </Helmet>
      <div style={{ marginTop: "10px" }}>
        <TranslucentCard>
          <Card.Body>
            <Tabs
              id="myaccount"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="logo" title="Logo">
                <Logo />
              </Tab>
              <Tab eventKey="myprofile" title="My Profile">
                <Row>
                  <Col sm={12} md={6}>
                    <Form onSubmit={handleProfileUpdate}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Your Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.name}
                            name="name"
                            autoFocus
                            onChange={handleInput}
                            required
                            type="text"
                            placeholder="Your name"
                          />
                        </InputGroup>
                      </Form.Group>
                      {/* <Form.Group id="email" className="mb-4">
                            <Form.Label>Your Email</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </InputGroup.Text>
                                <Form.Control value={inputField.email} name="email" autoFocus required onChange={handleInput} type="email" placeholder="example@company.com" />
                            </InputGroup>
                        </Form.Group> */}
                      <Form.Group id="companyName" className="mb-4">
                        <Form.Label>Company Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.companyName}
                            name="companyName"
                            required
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="Company Name"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="street" className="mb-4">
                        <Form.Label>Street</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.street}
                            name="street"
                            required
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="Street"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="city" className="mb-4">
                        <Form.Label>City</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.city}
                            name="city"
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="City"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="zip" className="mb-4">
                        <Form.Label>Zip</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.zip}
                            name="zip"
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="Zip"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="state" className="mb-4">
                        <Form.Label>State</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.state}
                            name="state"
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="State"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="country" className="mb-4">
                        <Form.Label>Country</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faBuilding} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.country}
                            name="country"
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="Country"
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group id="telephone" className="mb-4">
                        <Form.Label>Contact</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faMobile} />
                          </InputGroup.Text>
                          <Form.Control
                            value={inputField.address?.telephone}
                            name="telephone"
                            onChange={handleNestedInput}
                            type="text"
                            placeholder="Contact number"
                          />
                        </InputGroup>
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="changepwd" title="Change Password">
                <ChangePwd />
              </Tab>

              <Tab eventKey="invoiceSetting" title="Invoice Setting">
                <Row>
                  <Col md={6}>
                    <Form onSubmit={handleInvoiceSetting}>
                      <Form.Group
                        className="mb-3"
                        controlId="invoiceStartSeries"
                      >
                        <Form.Label>Invoice Start Series</Form.Label>
                        <Form.Control
                          style={{ textAlign: "left" }}
                          value={inputField.invoiceSetting?.invoiceStartSeries}
                          name="invoiceStartSeries"
                          onChange={handleFormChangeInvoiceSetting}
                          type="number"
                          placeholder="Invoice Start Series"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="invoiceStartSeries"
                      >
                        <Form.Label>Invoice Prefix</Form.Label>
                        <Form.Control
                          value={inputField.invoiceSetting?.invoicePrefix}
                          name="invoicePrefix"
                          onChange={handleFormChangeInvoiceSetting}
                          type="text"
                          placeholder="Invoice Prefix"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="invoiceStartSeries"
                      >
                        <Form.Label>Gold Rate Updates</Form.Label>
                        <div>
                          <Form.Check
                            inline
                            checked={
                              inputField.invoiceSetting?.goldRateUpdates?.live
                                ? true
                                : false
                            }
                            name="live"
                            label="Live"
                            onChange={handleFormChangeInvoiceSetting}
                            type="radio"
                            // placeholder="Invoice Prefix"
                          />
                          <Form.Check
                            inline
                            checked={
                              inputField.invoiceSetting?.goldRateUpdates
                                ?.openingPrice
                                ? true
                                : false
                            }
                            name="openingPrice"
                            label="Opening Price"
                            onChange={handleFormChangeInvoiceSetting}
                            type="radio"
                            // placeholder="Invoice Prefix"
                          />
                        </div>
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Card.Body>
        </TranslucentCard>
      </div>
    </>
  );
};
