import React, { Component } from "react";
import styles from "./Invoice.module.scss";

import LineItems from "./LineItems";
import PaymentItems from "./PaymentItems";

import { v4 as uuidv4 } from "uuid";
import axios from "../../axios-util/customAxis";
// import { useHistory } from 'react-router-dom';
// import CompanyLogo from "../../assets/img/technologies/logo.png";
import { Helmet } from "react-helmet-async";

// select
import CreatableSelect from "react-select/creatable";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { AiFillPrinter, AiFillSave } from "react-icons/ai";
import { RiFilePaper2Fill } from "react-icons/ri";

// html to print invoice
import InvoiceToPrint from "./InvoiceToPrint";

// alert
import { withAlert } from "react-alert";

class Invoice extends Component {
  locale = "en-US";
  currency = "USD";
  invoiceId = null;
  state = {
    taxRate: 0.0,
    lineItems: [
      {
        id: uuidv4(), // react-beautiful-dnd unique key
        name: "",
        quantity: 0,
        price: 0.0,
        itemId: "",
      },
    ],
    paymentItems: [
      {
        id: uuidv4(), // react-beautiful-dnd unique key
        paymentMode: "",
        amount: 0,
        paymentDate: this.getTodaysDate(null),
        paymentTypeId: "",
      },
    ],
    invoiceNumber: "",
    customerName: "",
    customerAddress: "",
    phone: "",
    isPaid: false,
    invoiceDate: this.getTodaysDate(null),
    myFlag: true,
    isFormValid: true,
    user: "",
    customerDB: [],
    paymentDB: [],
    itemDB: [],
    customerData: [],
    newCustomerModal: false,
    customerId: "",
    newCustomerData: {
      customerName: "",
      phone: "",
      customerAddress: {
        street: "",
        city: "",
        zip: "",
        state: "",
        country: "",
      },
      fullAddress: "",
      shippingName: "",
      sortBy: "",
      jewelryLabor: "",
      popup: false,
      user: "",
      prohibit: false,
      code: "",
    },
    disableCustomerDetailsInput: false,
    itemSelected: false,
    paymentSelected: false,
    isPrinting: false,
  };

  handleFormValidation() {
    let errors = [];
    if (!this.state["customerName"]) {
      errors.push("Cutomer name can't be empty");
    }
    if (!this.state["phone"]) {
      errors.push("Phone number can't be empty");
    }
    this.state.lineItems.forEach((item, i) => {
      if (!item["name"]) {
        errors.push(`Item name at row ${i + 1} can't be empty`);
      }
    });
    if (errors.length) {
      // this.setState({ ['isFormValid']: false });
    } else {
      this.setState({ ["isFormValid"]: true });
    }
  }

  componentDidMount() {
    this.handleFormValidation();
    let invoiceId = new URLSearchParams(this.props.location.search).get("id");
    this.invoiceId = invoiceId;
    this.getProfile(invoiceId);
    this.getCustomers();
    this.getPaymentMethods();
    this.getItems();
  }
  getProfile = (invoiceId) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`)
      .then((resp) => {
        this.setState({ ["user"]: resp.data });
        this.setState({
          ["newCustomerData"]: {
            ...this.state.newCustomerData,
            ["user"]: resp.data,
          },
        });
        if (invoiceId) {
          this.getInvoiceDetailsbyId(invoiceId);
        } else {
          this.getInvoiceNumber();
        }
      })
      .catch((err) => {});
  };
  getCustomers = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`)
      .then((resp) => {
        this.setState({ ["customerData"]: resp.data });
        let data = [];
        resp.data.map((res) => {
          data.push({
            value: res.customerName,
            label: res.customerName,
            id: res._id,
          });
        });
        this.setState({ ["customerDB"]: data });
      })
      .catch((err) => {});
  };

  getPaymentMethods = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/paymentmaster/showpayment`
      )
      .then((resp) => {
        let data = [];
        resp.data.map((res) => {
          data.push({
            value: res.paymentModeName,
            label: res.paymentModeName,
            id: res._id,
          });
        });
        this.setState({ ["paymentDB"]: data });
      })
      .catch((err) => {});
  };

  getItems = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/item/showitem`)
      .then((resp) => {
        let data = [];
        resp.data.map((res) => {
          data.push({ value: res.item, label: res.item, itemId: res._id });
        });
        this.setState({ ["itemDB"]: data });
      })
      .catch((err) => {});
  };

  getInvoiceDetailsbyId = (invoiceId) => {
    this.setState({ ["disableCustomerDetailsInput"]: true });
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/${invoiceId}`)
      .then((response) => {
        const { user } = this.state;
        // console.log(response.data);
        this.setState({ ...response["data"], ["user"]: user });

        // console.log(response.data.customerName);
        // axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`).then((resp)=>{
        //     this.setState({['customerData']: resp.data});
        //     let data = [];
        //     resp.data.map((res) => {
        //         data.push({value: res.customerName, label: res.customerName, id: res._id});
        //     })
        //     this.setState({['customerDB']:data});
        //   }).catch((err)=>{
        // });

        // this.setState({ ['invoiceDate']: this.getTodaysDate(response['data']['invoiceDate']) });

        // calling the API
        this.getCustomers();
        this.getPaymentMethods();
        this.getItems();
      })
      .catch((error) => {
        this.getInvoiceNumber();
        // console.log(error);
      });
  };
  getInvoiceNumber = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/number`)
      .then((response) => {
        // handle success
        this.setState({ ["invoiceNumber"]: response["data"]["invoiceNumber"] });
        //   console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  getTodaysDate(mydate) {
    let invdate = mydate ? new Date(mydate) : new Date();
    let formatedDate = invdate.toISOString().substr(0, 10);
    return formatedDate;
  }

  handleInvDateChange = (event) => {
    //   console.log(new Date().toLocaleDateString());
    this.setState({ [event.target.name]: event.target.value });
    this.handleFormValidation();
  };
  handleIsPaid = (event) => {
    this.setState({ [event.target.name]: !this.state.isPaid });
    this.handleFormValidation();
  };
  handleInvoiceChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.handleFormValidation();
  };

  handleLineItemChange = (elementIndex) => (event) => {
    let vaule = event.target.value
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");
    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      return { ...item, [event.target.name]: vaule };
    });
    this.setState({ lineItems });
    this.handleFormValidation();
    this.setState({ ["itemSelected"]: true });
  };

  handlePaymentItemChange = (elementIndex) => (event) => {
    // console.log(event.target.name, ":", event.target.value);
    let vaule = event.target.value
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");
    let paymentItems = this.state.paymentItems.map((item, i) => {
      if (elementIndex !== i) return item;
      return { ...item, [event.target.name]: vaule };
    });
    this.setState({ paymentItems });
    this.handleFormValidation();
    this.setState({ ["paymentSelected"]: true });
  };

  handleAddLineItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: this.state.lineItems.concat([
        { id: uuidv4(), name: "", quantity: 0, price: 0.0 },
      ]),
    });
    this.handleFormValidation();
  };
  handleAddPaymentItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      paymentItems: this.state.paymentItems.concat([
        {
          id: uuidv4(),
          paymentMode: "",
          amount: 0,
          paymentDate: this.getTodaysDate(null),
        },
      ]),
    });
    this.handleFormValidation();
  };

  handleRemoveLineItem = (elementIndex) => (event) => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };
  handleRemovePaymentItem = (elementIndex) => (event) => {
    this.setState({
      paymentItems: this.state.paymentItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleNewInvoice = () => {
    // window.location = "/#/invoice";
    // this.context.router.history.push("/invoice");
    this.props.history.push("/invoice");
    window.location.reload(false);
  };

  handleFocusSelect = (event) => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    // this.setState({ myFlag: false });
    // let add_item = document.getElementById('add_item');
    // let pay_add_item = document.getElementById('pay_add_item');
    // // let delete_button = document.getElementsByClassName('deleteItem');
    // let pay_delete_button = document.getElementById('pay_delete_button');
    // add_item.style["display"] = "none";
    // pay_add_item.style["display"] = "none";
    // let lineItems = document.getElementById('lineItem');
    // lineItems.style.marginBottom = '0px';
    // let payItem = document.getElementById('payItem');
    // payItem.style.marginBottom = '0px';
    // let customerAddress = document.getElementById('customerAddress');
    // let cAddress = document.getElementById('innerCustDiv');
    // customerAddress.style.border = 'none';
    // cAddress.style.marginLeft = "-20px"

    // setTimeout(() => { window.print() }, 1000);
    // setTimeout(() => {
    //     this.setState({ myFlag: true });
    //     add_item.style["display"] = "initial";
    //     pay_add_item.style["display"] = "initial";
    //     lineItems.style.marginBottom = '80px';
    //     payItem.style.marginBottom = '80px';
    //     customerAddress.style.border = '1px';
    //     cAddress.style.marginLeft = "0px";

    // }, 1000);

    // new code

    this.setState({ isPrinting: true });

    setTimeout(() => {
      window.print();
    }, 1000);
    setTimeout(() => {
      this.setState({ isPrinting: false });
    }, 1000);
  };
  handleSaveButtonClick = () => {
    let invoiceId = this.invoiceId;
    //Update
    if (invoiceId) {
      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/${invoiceId}`,
          this.state
        )
        .then((resp) => {
          if (resp.data.message) {
            if (
              resp.data.message.code === 11000 ||
              resp.data.message.name === "ValidationError"
            ) {
              alert("Invoice already exist");
            }
          } else {
            alert("Invoice updated sucessfully");
            this.props.history.push("/invoicehistory");
          }
        })
        .catch((err) => {
          alert("Could not update invoice!");
        });
    } else {
      if (!this.state.itemSelected) {
        return alert("Item cannot be empty");
      }

      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice`, this.state)
        .then((resp) => {
          if (resp.data.message) {
            if (
              resp.data.message.code === 11000 ||
              resp.data.message.name === "ValidationError"
            ) {
              alert("Invoice already exist");
            }
          } else {
            alert("Invoice added sucessfully");

            this.props.history.push("/invoicehistory");
          }
        })
        .catch((err) => {
          // if(err.)
          console.log(err.message);
          alert("Could not save invoice!");
        });
    }
  };

  formatCurrency = (amount) => {
    return new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100);
  };

  calcLineItemsTotal = () => {
    return this.state.lineItems.reduce(
      (prev, cur) => prev + cur.quantity * cur.price,
      0
    );
  };

  calcTaxTotal = () => {
    return this.calcLineItemsTotal() * (this.state.taxRate / 100);
  };

  calcGrandTotal = () => {
    return this.calcLineItemsTotal() + this.calcTaxTotal();
  };

  calcTotalGoldWt = () => {
    return this.precise(
      this.state.lineItems.reduce(
        (prev, cur) => parseFloat(prev) + parseFloat(cur.quantity),
        0
      )
    );
  };
  precise(x) {
    return Number.parseFloat(x).toPrecision();
    // gold weight not round off
    // add toPrecision(4) for round off weight
  }
  calcAmtPaid = () => {
    return this.state.paymentItems.reduce(
      (prev, cur) => prev + parseFloat(cur.amount),
      0
    );
  };

  calcBalance = () => {
    const paymentTotal = this.state.paymentItems.reduce(
      (prev, cur) => prev + parseFloat(cur.amount),
      0
    );
    const total = this.calcGrandTotal();
    return total - paymentTotal;
  };

  checkNewCustomer = (customerName) => {
    if (customerName) {
      // console.log(customerName);

      let checkValue = (value, arr) => {
        var status = false;

        for (var i = 0; i < arr.length; i++) {
          var name = arr[i].value;
          if (name == value) {
            status = true;
            break;
          }
        }
        return status;
      };

      if (!checkValue(customerName, this.state.customerDB)) {
        this.setState({ ["newCustomerModal"]: true });
        this.setState({
          ["newCustomerData"]: {
            ...this.state.newCustomerData,
            ["customerName"]: customerName,
          },
        });
      }
    }
  };

  handleChange = (e) => {
    // console.log(e);
    if (e) {
      this.setState({ ["customerName"]: e.value });

      this.state.customerData.map((cus) => {
        if (cus._id === e.id) {
          this.setState({ ["customerId"]: { _id: e.id } });
          this.setState({ ["phone"]: cus.phone });
          this.setState({ ["customerAddress"]: cus.fullAddress });
          this.setState({ ["disableCustomerDetailsInput"]: true });
        }
      });

      this.checkNewCustomer(e.value);
    }
  };

  // close new customer modal

  handleClose = () => this.setState({ ["newCustomerModal"]: false });

  // handle new customer inputs

  onChangeText = (e) => {
    this.setState({
      ["newCustomerData"]: {
        ...this.state.newCustomerData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeAddressValue = (e) => {
    let data = { ...this.state.newCustomerData };
    data.customerAddress[e.target.name] = e.target.value;
    this.setState({ ["newCustomerData"]: data });
  };

  // save new customer

  saveNewCustomer = () => {
    let mainDat = {
      ...this.state.newCustomerData,
      fullAddress: `${this.state.newCustomerData.customerAddress.street} ${this.state.newCustomerData.customerAddress.city} ${this.state.newCustomerData.customerAddress.zip} ${this.state.newCustomerData.customerAddress.state}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/customer/create/`,
        mainDat
      )
      .then((resp) => {
        this.setState({
          ["newCustomerData"]: {
            customerName: "",
            phone: "",
            customerAddress: {
              street: "",
              city: "",
              zip: "",
              state: "",
              country: "",
            },
            fullAddress: "",
            shippingName: "",
            sortBy: "",
            jewelryLabor: "",
            popup: false,
            user: "",
            prohibit: false,
            code: "",
          },
        });
        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            alert("Customer already exist");
            this.setState({ ["newCustomerModal"]: false });
            this.setState({ ["disableCustomerDetailsInput"]: true });
          }
        } else {
          // here i have to put the customer ID on our local state and also add customer details for showing the ui
          // console.log(resp.data._id);
          this.setState({ ["customerId"]: { _id: resp.data._id } });
          this.setState({ ["customerName"]: resp.data.customerName });
          this.setState({ ["customerAddress"]: resp.data.fullAddress });
          this.setState({ ["phone"]: resp.data.phone });

          alert("Customer Added sucessfully");
          this.setState({ ["newCustomerModal"]: false });
          this.getCustomers();
        }
        // loadCustomer();
      })
      .catch((err) => {
        this.setState({
          ["newCustomerData"]: {
            customerName: "",
            phone: "",
            customerAddress: {
              street: "",
              city: "",
              zip: "",
              state: "",
              country: "",
            },
            fullAddress: "",
            shippingName: "",
            sortBy: "",
            jewelryLabor: "",
            popup: false,
            user: "",
            prohibit: false,
            code: "",
          },
        });
        alert("Could not add customer!");
      });
  };

  render = () => {
    const { myFlag, isFormValid, user, disableCustomerDetailsInput } =
      this.state;

    // console.log(this.showCustomer(this.invoiceId))

    const alertS = this.props.alert;

    return (
      <>
        <Helmet>
          <title>Invoice App - Invoice</title>
        </Helmet>

        {!this.state.isPrinting ? (
          <div id="inv" className={styles.invoice}>
            <div
              className=""
              style={{
                display: "flex",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <div className="text-center">
                <img
                  style={{}}
                  src={`${process.env.REACT_APP_API_ENDPOINT}/img/${this.state.user.profilePic}`}
                  width={180}
                  height={150}
                  alt="Logo"
                  className={styles.logo}
                />
                {this.state.user?.address && (
                  <div style={{}} className={styles.companyAddress}>
                    <strong>{this.state.user?.address.companyName}</strong>
                    <br />
                    {this.state.user?.address.addressLine1}
                    <br />
                    {this.state.user?.address.addressLine2}
                    <br />
                    {this.state.user?.address.telephone}
                  </div>
                )}
              </div>
              <div style={{ flexGrow: 2 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{ marginLeft: "0px" }}
                    className={`${styles.valueTable} ${styles.to}`}
                  >
                    <div className={styles.row}>
                      <div className={styles.label}>Invoice #</div>
                      <div className={styles.value}>
                        <input
                          name="invoiceNumber"
                          value={this.state.invoiceNumber}
                          onChange={this.handleInvoiceChange}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.label}>Date</div>
                      <div className={`${styles.value} ${styles.date}`}>
                        <input
                          name="invoiceDate"
                          value={this.state.invoiceDate}
                          defaultValue={this.state.invoiceDate}
                          type="date"
                          onChange={this.handleInvDateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
                        <div className={`${styles.valueTable} ${styles.to}`}>
                            <div className={styles.row}>
                                <div className={styles.label}>Invoice #</div>
                                <div className={styles.value}>{this.state.invoiceNumber}</div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Date</div>
                                <div className={`${styles.value} ${styles.date}`}>
                                    <input name="invoiceDate" value={this.state.invoiceDate} defaultValue={this.state.invoiceDate} type="date" onChange={this.handleInvDateChange}></input>
                                </div>
                            </div>
                        </div>
                    </div> */}

            {/* <hr/> */}
            <div className={styles.addresses}>
              <div className={styles.from}>
                <div>
                  <label>Customer Name:</label>
                  {/* <input value={this.state.customerName} onChange={this.handleInvoiceChange} name="customerName" type="text" /> */}

                  {/* <CreatableSelect
                                    isClearable
                                    onChange={this.handleChange}
                                    options={this.state.customerDB}
                                    defaultValue={[{ label: `${this.state.customerName}`, value: `${this.state.customerId}` }]}
                                /> */}

                  {this.invoiceId ? (
                    <CreatableSelect
                      isClearable
                      onChange={this.handleChange}
                      options={this.state.customerDB}
                      value={[
                        {
                          label: `${this.state.customerName}`,
                          value: `${this.state.customerId}`,
                        },
                      ]}
                    />
                  ) : (
                    <CreatableSelect
                      isClearable
                      onChange={this.handleChange}
                      options={this.state.customerDB}
                    />
                  )}
                </div>
                <div>
                  <label>Phone:</label>
                  <input
                    value={this.state.phone}
                    onChange={this.handleInvoiceChange}
                    name="phone"
                    style={{ textAlign: "left" }}
                    type="number"
                    disabled={disableCustomerDetailsInput}
                  />
                </div>
                {/* <div><label>Paid:</label><input checked={this.state.isPaid} onChange={this.handleIsPaid} name="isPaid" type="checkbox" /></div> */}
                {/* <div><label>Address:</label><textarea name="address"/></div> */}
              </div>
              <div
                id="customerAddress"
                style={{ marginRight: "0px" }}
                className={`${styles.valueTable} ${styles.from}`}
              >
                <div id="innerCustDiv">
                  <label>Customer Address:</label>
                  <textarea
                    className="form-control"
                    onChange={this.handleInvoiceChange}
                    value={this.state.customerAddress}
                    rows={5}
                    cols={29}
                    name="customerAddress"
                    disabled={disableCustomerDetailsInput}
                  />
                </div>
              </div>
            </div>
            <h5>Invoice</h5>

            <LineItems
              items={this.state.lineItems}
              currencyFormatter={this.formatCurrency}
              addHandler={this.handleAddLineItem}
              changeHandler={this.handleLineItemChange}
              focusHandler={this.handleFocusSelect}
              deleteHandler={this.handleRemoveLineItem}
              reorderHandler={this.handleReorderLineItems}
              itemsDB={this.state.itemDB}
            />
            <h5>Payment</h5>
            <PaymentItems
              items={this.state.paymentItems}
              currencyFormatter={this.formatCurrency}
              addHandler={this.handleAddPaymentItem}
              changeHandler={this.handlePaymentItemChange}
              focusHandler={this.handleFocusSelect}
              deleteHandler={this.handleRemovePaymentItem}
              reorderHandler={this.handleReorderLineItems}
              paymentsMethod={this.state.paymentDB}
            />

            <div className={styles.totalContainer}>
              <form>
                {/* <div className={styles.valueTable}>
                        
                        </div> */}
              </form>
              <form>
                <div className={styles.valueTable}>
                  {/* <div className={styles.row}>
                                <div className={styles.label}>Subtotal</div>
                                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcLineItemsTotal())}</div>
                            </div> */}
                  <div className={styles.row}>
                    <div className={styles.label}>Total Gold Wt.</div>
                    <div className={`${styles.value} text-end`}>
                      {this.calcTotalGoldWt()}
                    </div>
                  </div>
                  {/* <div className={styles.row}>
                                <div className={styles.label}>Tax ({this.state.taxRate}%)</div>
                                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcTaxTotal())}</div>
                            </div> */}
                  <div className={styles.row}>
                    <div className={styles.label}>Total Due</div>
                    <div className={`${styles.value} ${styles.currency}`}>
                      {this.formatCurrency(this.calcGrandTotal())}
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.label}>Amount Payed</div>
                    <div className={`${styles.value} ${styles.currency}`}>
                      {this.formatCurrency(this.calcAmtPaid())}
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.label}>Balance</div>
                    <div className={`${styles.value} ${styles.currency}`}>
                      {this.formatCurrency(this.calcBalance())}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className={styles.pay}>
              {/* {
                            isFormValid && <>
                                {myFlag && <button className={styles.payNow} onClick={this.handleSaveButtonClick}>Save</button>}
                                {myFlag && <button className={styles.payNow} onClick={this.handleNewInvoice}>New</button>}
                            </>
                        }
                        {myFlag && <button className={styles.payNow} onClick={this.handlePayButtonClick}>Print</button>} */}
              {isFormValid && (
                <>
                  {myFlag && (
                    <button
                      className="btn btn-md btn-outline-primary m-2"
                      onClick={this.handleSaveButtonClick}
                    >
                      Save <AiFillSave />
                    </button>
                  )}
                  {myFlag && (
                    <button
                      className="btn btn-md btn-outline-primary m-2"
                      onClick={this.handleNewInvoice}
                    >
                      New <RiFilePaper2Fill />
                    </button>
                  )}
                  {myFlag && (
                    <button
                      className="btn btn-md btn-outline-primary m-2"
                      onClick={this.handlePayButtonClick}
                    >
                      Print <AiFillPrinter />
                    </button>
                  )}
                </>
              )}
              {/* {myFlag && <button className="btn btn-md btn-outline-primary m-2" onClick={this.handlePayButtonClick}>Print <AiFillPrinter /></button>} */}
            </div>

            <div className={styles.footer}>
              {/* <div className={styles.comments}>
            <h4>Notes</h4>
            <p>Demo by Kevin Firko, independent consulting developer at <a href="https://bitcurve.com">Bitcurve</a>.</p>
            <p>Check out my blog <a href="https://firxworx.com">https://firxworx.com</a> and <a href="https://firxworx.com/blog/coding/creating-an-invoice-component-with-dynamic-line-items-using-react/">the related post</a> covering how to create a dynamic Invoice component in React. </p>
            <p>Find the <a href="https://github.com/firxworx/react-simple-invoice">code on Github</a>.</p>
          </div> */}
              {/* <div className={styles.closing}>
                        <div>Thank you for your business</div>
                    </div> */}
            </div>
          </div>
        ) : (
          <InvoiceToPrint
            data={this.state}
            currencyFormatter={this.formatCurrency}
            calcTotalGoldWt={this.calcTotalGoldWt}
            calcGrandTotal={this.calcGrandTotal}
            calcAmtPaid={this.calcAmtPaid}
            calcBalance={this.calcBalance}
          />
        )}
        {/* <InvoiceToPrint data={this.ssstate} currencyFormatter={this.formatCurrency} calcTotalGoldWt={this.calcTotalGoldWt} calcGrandTotal={this.calcGrandTotal} calcAmtPaid={this.calcAmtPaid} calcBalance={this.calcBalance} /> */}

        {/* confirm the new customer modal  */}
        <Modal show={this.state.newCustomerModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.customerName} is new customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="text-center">
                            <h6>Please save customer details.</h6>
                        </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                  <input
                    type="text"
                    placeholder="Customer Name"
                    name="customerName"
                    className="form-control h-100"
                    value={this.state.newCustomerData.customerName}
                    onChange={(e) => this.onChangeText(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <input
                    type="number"
                    placeholder="Customer Number"
                    name="phone"
                    className="form-control h-100"
                    value={this.state.newCustomerData.phone}
                    onChange={(e) => this.onChangeText(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                  <input
                    placeholder="Street"
                    name="street"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.street}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <input
                    placeholder="City"
                    name="city"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.city}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="Zip"
                    name="zip"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.zip}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="State"
                    name="state"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.state}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="Country"
                    name="country"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.country}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.saveNewCustomer}>
              Save Customer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
}

export default withAlert(Invoice);
