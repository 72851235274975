import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdCancel as DeleteIcon } from 'react-icons/md'
import styles from './LineItem.module.scss'
import CreatableSelect from 'react-select/creatable';

class LineItem extends Component {

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  render = () => {

    const { index, name, itemId, item, description, quantity, price, itemsDB } = this.props

    // console.log(itemsDB);

    return (
      <div className={styles.lineItem}>
        <div>{index + 1}</div>
        {/* <div><input name="name" type="text" value={name} onChange={this.props.changeHandler(index)} /></div> */}

        <div>
          <select className="w-100" name="itemId" value={itemId} onChange={this.props.changeHandler(index)}>
            <option selected>Select Item</option>
            {
              itemsDB.map((it) => (
                <>
                  <option value={it.itemId}>{it.label}</option>
                </>
              ))
            }

          </select>
        </div>
        {/* <div>
          <CreatableSelect
            isClearable
            styles={this.customStyles}
            onChange={this.props.changeHandler(index)}
            options={itemsDB}
          // defaultValue={this.showCustomer}
          />
        </div> */}

        {/* <div><input name="description" type="text" value={description} onChange={this.props.changeHandler(index)} /></div> */}
        <div><input name="quantity" type="number" step="1" value={quantity} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        <div className={styles.currency}><input name="price" type="number" step="0.01" min="0.00" max="9999999.99" value={price} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        <div className={styles.currency}>{this.props.currencyFormatter(quantity * price)}</div>
        <div>
          <button type="button"
            className={styles.deleteItem}
            onClick={this.props.deleteHandler(index)}
          ><DeleteIcon size="1.25em" /></button>
        </div>
      </div>
    )
  }
}

export default LineItem

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  itemId: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemsDB: PropTypes.object
}


