import React, { useEffect, useState } from "react";
import axios from "../../../axios-util/customAxis";
// import styles from './CustomerMaster.module.scss';
import { Card, Button, Row, Col, Modal } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useHistory } from "react-router";
// import { Routes } from '../../../routes';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Helmet } from "react-helmet-async";

//alert
import { useAlert } from "react-alert";
import TranslucentCard from "../../components/MaterialUI/Card/TranslucentCard";
import { MyPhoneInput } from "../../components/MaterialUI";

export default () => {
  const alert = useAlert();

  let history = useHistory();
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "customerName",
      text: "Customer Name",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "fullAddress",
      text: "Customer Address",
    },

    {
      dataField: "popupnotes",
      text: "Customer Notes",
    },
  ];
  const rowStyle = { padding: "5px 0", cursor: "pointer" };
  const options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    paginationSize: 5,
  };
  const [customerDB, setCustomerDB] = useState([]);
  const [show, setShow] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState("");
  const [editCustomerData, setEditCustomerData] = useState({
    customerName: "",
    phone: "",
    customerAddress: {
      street: "",
      city: "",
      zip: "",
      state: "",
      country: "",
    },
    fullAddress: "",
    shippingName: "",
    sortBy: "",
    jewelryLabor: "",
    popup: false,
    user: "",
    prohibit: false,
    code: "",
  });
  const [customerData, setCustomerData] = useState({
    customerName: "",
    phone: "",
    customerAddress: {
      street: "",
      city: "",
      zip: "",
      state: "",
      country: "",
    },
    fullAddress: "",
    shippingName: "",
    sortBy: "",
    jewelryLabor: "",
    popup: false,
    user: "",
    prohibit: false,
    code: "",
  });

  const getProfile = (invoiceId) => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`)
      .then((resp) => {
        // console.log(resp.data);
        setCustomerData({ ...customerData, user: resp.data });
        if (invoiceId) {
          this.getInvoiceDetailsbyId(invoiceId);
        }
      })
      .catch((err) => {});
  };
  const loadCustomer = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`)
      .then((resp) => {
        setCustomerDB(resp.data);

        // let data = { ...resp.data, deletedAction: "subham" }
        // setCustomerDB(data);
        // if(resp.data.length > 0){
        //     resp.data.map((res) => {
        //         if(res.paymentItems.length > 0){
        //             res.paymentItems.map((re) => {
        //                 console.log(re);
        //             })
        //         }
        //     })
        // }
        // if(resp.data.paymentItems.length > 0){
        //     console.log(resp.data.paymentItems)
        // }
      })
      .catch((err) => {
        // alert("Could not fetch details");
        alert.error("Could not fetch details");
      });
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(row._id);

      // if (!deleting) {
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/${row._id}`)
        .then((resp) => {
          setEditCustomerData(resp.data);
          setCustomerToDelete(resp.data._id);
          setEditCustomer(true);
          setShow(true);
        })
        .catch((err) => {
          // alert("Could not update invoice!");
          alert.error("Could not update invoice!");
        });
      // }
    },
  };

  const handleOpenModal = () => {
    setEditCustomer(false);
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleSaveModal = () => {
    let mainDat = {
      ...customerData,
      fullAddress: `${customerData.customerAddress.street} ${customerData.customerAddress.city} ${customerData.customerAddress.zip} ${customerData.customerAddress.state}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/customer/create/`,
        mainDat
      )
      .then((resp) => {
        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Customer already exist");
            window.alert("Customer with this contact number exists");
          }
        } else {
          // alert("Customer Added sucessfully");
          setShow(false);
          setCustomerData({
            customerName: "",
            phone: "",
            customerAddress: {
              street: "",
              city: "",
              zip: "",
              state: "",
              country: "",
            },
            fullAddress: "",
            shippingName: "",
            sortBy: "",
            jewelryLabor: "",
            popup: false,
            user: "",
            prohibit: false,
            code: "",
          });
          alert.success("Customer Added sucessfully");
        }
        loadCustomer();
      })
      .catch((err) => {
        // setCustomerData({
        //   customerName: "",
        //   phone: "",
        //   customerAddress: {
        //     street: "",
        //     city: "",
        //     zip: "",
        //     state: "",
        //     country: "",
        //   },
        //   fullAddress: "",
        //   shippingName: "",
        //   sortBy: "",
        //   jewelryLabor: "",
        //   popup: false,
        //   user: "",
        //   prohibit: false,
        //   code: "",
        // });
        // setShow(false);

        // alert("Could not add customer!");
        window.alert("Could not add customer!");
      });
  };
  const handleSaveModalUpdate = () => {
    let mainD = {
      ...editCustomerData,
      fullAddress: `${editCustomerData.customerAddress.street} ${editCustomerData.customerAddress.city} ${editCustomerData.customerAddress.zip} ${editCustomerData.customerAddress.state}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/customer/${editCustomerData._id}`,
        mainD
      )
      .then((resp) => {
        setEditCustomerData({
          customerName: "",
          phone: "",
          customerAddress: {
            street: "",
            city: "",
            zip: "",
            state: "",
            country: "",
          },
          fullAddress: "",
          shippingName: "",
          sortBy: "",
          jewelryLabor: "",
          popup: false,
          user: "",
          prohibit: false,
          code: "",
        });
        setShow(false);

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Customer already exist");
            alert.error("Customer already exist");
          }
        } else {
          // alert("Customer Updated sucessfully");
          alert.success("Customer Updated sucessfully");
        }
        loadCustomer();
      })
      .catch((err) => {
        setEditCustomerData({
          customerName: "",
          phone: "",
          customerAddress: {
            street: "",
            city: "",
            zip: "",
            state: "",
            country: "",
          },
          fullAddress: "",
          shippingName: "",
          sortBy: "",
          jewelryLabor: "",
          popup: false,
          user: "",
          prohibit: false,
          code: "",
        });
        setShow(false);
        // alert("Could not update customer!");
        alert.error("Could not update customer!");
      });
  };

  const handleCustomerDelete = () => {
    let data = {
      id: customerToDelete,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/delete`, data)
      .then((resp) => {
        setShow(false);
        loadCustomer();
        // alert("customer deleted");
        alert.error("customer deleted");
      })
      .catch((err) => {
        // alert("something went wrong");
        alert.error("something went wrong");
      });
  };

  const onChangeText = (e) => {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };
  const onChangeAddressValue = (e) => {
    let data = { ...customerData };
    data.customerAddress[e.target.name] = e.target.value;
    setCustomerData(data);
  };
  const onChangeTextUpdate = (e) => {
    setEditCustomerData({
      ...editCustomerData,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeAddressValueUpdate = (e) => {
    let data = { ...editCustomerData };
    data.customerAddress[e.target.name] = e.target.value;
    setEditCustomerData(data);
  };

  useEffect(() => {
    getProfile();
    loadCustomer();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoice App - Customer Master</title>
      </Helmet>

      <div className="mt-2">
        <TranslucentCard
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="p-0">
            <h3>Customer Master </h3>
            <ToolkitProvider
              keyField="_id"
              data={customerDB}
              columns={columns}
              search
            >
              {(props) => (
                <div style={{ marginTop: "20px" }}>
                  <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                      <SearchBar {...props.searchProps} />
                    </Col>

                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                      <Button
                        variant="primary"
                        onClick={handleOpenModal}
                        className="text-end"
                      >
                        New
                      </Button>
                    </Col>
                  </Row>

                  <hr />
                  <BootstrapTable
                    hover
                    bootstrap4={true}
                    rowStyle={rowStyle}
                    rowClasses="custom-row-class"
                    rowEvents={tableRowEvents}
                    bordered={false}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card.Body>
        </TranslucentCard>
      </div>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editCustomer ? "Edit" : "Add"} Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {editCustomer ? (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                    <input
                      type="text"
                      placeholder="Customer Name"
                      name="customerName"
                      className="form-control h-100"
                      value={editCustomerData.customerName}
                      onChange={(e) => onChangeTextUpdate(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                    <input
                      type="number"
                      placeholder="Customer Number"
                      name="phone"
                      className="form-control h-100"
                      value={editCustomerData.phone}
                      onChange={(e) => onChangeTextUpdate(e)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                  <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                    <input
                      placeholder="Street"
                      name="street"
                      className="form-control w-100"
                      value={editCustomerData.customerAddress.street}
                      onChange={(e) => onChangeAddressValueUpdate(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                    <input
                      placeholder="City"
                      name="city"
                      className="form-control w-100"
                      value={editCustomerData.customerAddress.city}
                      onChange={(e) => onChangeAddressValueUpdate(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="Zip"
                      name="zip"
                      className="form-control w-100"
                      value={editCustomerData.customerAddress.zip}
                      onChange={(e) => onChangeAddressValueUpdate(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="State"
                      name="state"
                      className="form-control w-100"
                      value={editCustomerData.customerAddress.state}
                      onChange={(e) => onChangeAddressValueUpdate(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="Country"
                      name="country"
                      className="form-control w-100"
                      value={editCustomerData.customerAddress.country}
                      onChange={(e) => onChangeAddressValueUpdate(e)}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                  <input
                    placeholder="Customer Notes"
                    name="popupnotes"
                    className="form-control w-100"
                    value={customerData?.popupnotes}
                    onChange={(e) => onChangeTextUpdate(e)}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                    <input
                      type="text"
                      placeholder="Customer Name"
                      name="customerName"
                      className="form-control h-100"
                      value={customerData.customerName}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                    <MyPhoneInput
                      international
                      defaultCountry="US"
                      name="phone"
                      placeholder="Customer Number"
                      value={customerData?.phone}
                      onChange={(value) => {
                        setCustomerData({
                          ...customerData,
                          phone: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                  <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                    <input
                      placeholder="Street"
                      name="street"
                      className="form-control w-100"
                      value={customerData.customerAddress.street}
                      onChange={(e) => onChangeAddressValue(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                    <input
                      placeholder="City"
                      name="city"
                      className="form-control w-100"
                      value={customerData.customerAddress.city}
                      onChange={(e) => onChangeAddressValue(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="Zip"
                      name="zip"
                      className="form-control w-100"
                      value={customerData.customerAddress.zip}
                      onChange={(e) => onChangeAddressValue(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="State"
                      name="state"
                      className="form-control w-100"
                      value={customerData.customerAddress.state}
                      onChange={(e) => onChangeAddressValue(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                    <input
                      placeholder="Country"
                      name="country"
                      className="form-control w-100"
                      value={customerData.customerAddress.country}
                      onChange={(e) => onChangeAddressValue(e)}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                  <input
                    placeholder="Customer Notes"
                    name="popupnotes"
                    className="form-control w-100"
                    value={customerData?.popupnotes}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {editCustomer ? (
            <>
              <Button variant="danger" onClick={handleCustomerDelete}>
                Delete Customer
              </Button>
              <Button variant="primary" onClick={handleSaveModalUpdate}>
                Update Customer
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleSaveModal}>
              Save Customer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
