import { Card } from "@mui/material";
import React from "react";
import styles from "./TranslucentCard.module.scss";
const TranslucentCard = ({ className = "", children, ...props }) => {
  return (
    <Card
      variant="outlined"
      className={`${styles.t_card} ${className}`}
      {...props}
    >
      {children}
    </Card>
  );
};

export default TranslucentCard;
