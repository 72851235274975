import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdCancel as DeleteIcon, MdOutlineCancel } from "react-icons/md";
import styles from "./PaymentItem2.module.scss";

// select
import CreatableSelect from "react-select/creatable";
import { TableCell } from "@mui/material";
import moment from "moment-timezone";
import MyDatePicker from "../components/MaterialUI/DatePicker/MyDatePicker";
import { MyAutocomplete } from "../components/MaterialUI";

class PaymentItem2 extends Component {
  // handleChange = (e) => {
  //     console.log(e);
  // };

  render = () => {
    const {
      index,
      paymentMode,
      paymentTypeId,
      amount,
      paymentDate,
      paymentsMethod,
      description,
    } = this.props;

    // console.log(description);

    return (
      <>
        {/* <TableCell>{index + 1}</TableCell> */}
        {/* <div><input name="paymentMode" type="text" value={paymentMode} onChange={this.props.changeHandler(index)} /></div> */}
        {/* <div>
          <CreatableSelect
            isClearable
            onChange={this.handleChange}
            options={paymentsMethod}
          />
        </div> */}
        <TableCell>
          <MyAutocomplete
            //   style={{ width: "200px" }}
            className="form-control"
            name="paymentTypeId"
            value={paymentTypeId}
            variant="standard"
            id="paymentTypeId"
            // style={{ width: "200px" }}
            getOptionLabel={(option) =>
              option?.paymentModeName ? option.paymentModeName : ""
            }
            InputProps={{ id: "paymentTypeId" }}
            options={paymentsMethod || []}
            onChange={(event, value) => {
              event = {
                target: {
                  name: "paymentTypeId",
                  value: value,
                },
              };
              this.props.changeHandler(index)(event);
            }}
            sx={{ width: 150 }}
          />
        </TableCell>

        <TableCell>
          <input
            name="description"
            type="text"
            // value={description}
            // onChange={this.props.changeHandler(index)}
            defaultValue={description}
            onBlur={this.props.changeHandler(index)}
          />
        </TableCell>
        <TableCell>
          <input
            className="form-control"
            name="amount"
            type="number"
            step="1"
            style={{ textAlign: "left" }}
            // value={amount}
            // onChange={this.props.changeHandler(index)}
            // onFocus={this.props.focusHandler}
            defaultValue={amount}
            onBlur={this.props.changeHandler(index)}
          />
        </TableCell>
        <TableCell>
          <MyDatePicker
            className="form-control"
            name="paymentDate"
            value={paymentDate}
            onChange={(value) => {
              const ev = { target: { name: "paymentDate", value: value } };
              this.props.changeHandler(index)(ev);
            }}
            onFocus={this.props.focusHandler}
          />
          {/* <input
            className="form-control"
            name="paymentDate"
            type="date"
            defaultValue={new Date(paymentDate)}
            value={new Date(paymentDate)}
            onChange={this.props.changeHandler(index)}
            onFocus={this.props.focusHandler}
          /> */}
        </TableCell>
        {/* <div className={styles.currency}>{this.props.currencyFormatter( quantity * price )}</div> */}
        <TableCell>
          <button
            id="pay_delete_button"
            type="button"
            className={styles.deleteItem}
            onClick={this.props.deleteHandler(index)}
          >
            <MdOutlineCancel size="1.6em" />
          </button>
        </TableCell>
      </>
    );
  };
}

export default PaymentItem2;

PaymentItem2.propTypes = {
  index: PropTypes.number.isRequired,
  paymentMode: PropTypes.string,
  paymentTypeId: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentDate: PropTypes.string,
  paymentsMethod: PropTypes.object,
  description: PropTypes.string,
};
