import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MySvg } from "../JewelPay.svg";
import {
  faAngleLeft,
  faEnvelope,
  faPhone,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import HomePageContainer from "./HomePageContainer";
import TranslucentCard from "../components/MaterialUI/Card/TranslucentCard";

export default () => {
  const history = useHistory();
  const { saveToken } = useToken();
  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });
  const handleInput = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/login`, loginData)
      .then((res) => {
        saveToken(res.data);
        window.location = "/#/home";
        // history.push("/home");
      })
      .catch((error) => {
        // console.log(error);
        alert("Invalid username or password OR user logged in");
      });
  };
  return (
    <HomePageContainer>
      <div>
        <Typography variant="h5" gutterBottom component="div">
          Invoicing Made Super Easy..
        </Typography>
      </div>
      <TranslucentCard sx={{ width: "90%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <iframe
              width="100%"
              height="100"
              src="https://www.youtube.com/embed/LXb3EKWsInQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TranslucentCard sx={{ width: "100%", height: "100%" }}>
              Updated gold price present in the invoice. Just enter labor Let us
              do the rest
            </TranslucentCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <iframe
              width="100%"
              height="100"
              src="https://www.youtube.com/embed/LXb3EKWsInQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TranslucentCard sx={{ width: "100%", height: "100%" }}>
              Look Professional with amazing looking invoices
            </TranslucentCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <iframe
              className="rounded"
              width="100%"
              height="100"
              src="https://www.youtube.com/embed/LXb3EKWsInQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TranslucentCard sx={{ width: "100%", height: "100%" }}>
              Analyse or monthly report to see who owes you what
            </TranslucentCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <iframe
              width="100%"
              height="100"
              src="https://www.youtube.com/embed/LXb3EKWsInQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TranslucentCard sx={{ width: "100%", height: "100%" }}>
              Make printed invoice right at the counter
            </TranslucentCard>
          </Grid>
        </Grid>
      </TranslucentCard>
      <Button className="my-2">FREE TRIAL</Button>
    </HomePageContainer>
  );
};
