import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const MyPhoneInput = ({ value, onChange, placeholder, ...props }) => {
  return (
    <PhoneInput
      {...props}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default React.forwardRef(MyPhoneInput);
