import { Button } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import SignaturePad from "react-signature-canvas";

import styles from "./signStyles.css";

const MySignatureCanvas = (props) => {
  const { trimmedDataURL, setTrimmedDataUrl } = props;
  let sigPad = {};

  const clear = () => {
    sigPad && sigPad.clear();
  };

  const trim = () => {
    setTrimmedDataUrl(
      sigPad && sigPad.getTrimmedCanvas().toDataURL("image/png")
    );

    props.setOpenSign && props.setOpenSign(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.sigContainer}>
        <SignaturePad
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: styles.sigPad }}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="outlined" color="secondary" onClick={clear}>
          Cancel
        </Button>
        <Button
          sx={{ marginLeft: 3 }}
          variant="contained"
          color="secondary"
          onClick={trim}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default MySignatureCanvas;
