import { configureStore } from '@reduxjs/toolkit'
import invoiceHistory_slice from "../reducer/invoiceHistory.slice"
import { composeWithDevTools } from "redux-devtools-extension";


export default configureStore({
    reducer: {
        invoiceHistory_slice,
    },
    composeWithDevTools
})