import React from "react";
import CustomerMaster from "./customer/CustomerMaster";
import ItemMaster from "./item/ItemMaster";
import PaymentMaster from "./payment/PaymentMaster";

const AllMasterData = () => {
  return (
    <>
      <CustomerMaster />
      <ItemMaster />
      <PaymentMaster />
    </>
  );
};

export default AllMasterData;
