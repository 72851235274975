import { Paper } from "@mui/material";
import React from "react";

const MySection = ({ children, ...props }) => {
  return (
    <Paper
      className={props.className}
      style={{ marginBottom: "24px", padding: "8px", ...props.style }}
    >
      {children}
    </Paper>
  );
};

export default MySection;
