import { createSlice } from '@reduxjs/toolkit'

export const invoiceHistory_slice = createSlice({
    name: 'invoiceHistory_slice',

    initialState: {
        searchData: {
            customerSearchData: "",
            invoiceSearchData: ""
        },
    },

    reducers: {
        customerSearchData: (state, action) => {
            state.searchData.customerSearchData = action.payload;
        },
        invoiceSearchData: (state, action) => {
            state.searchData.invoiceSearchData = action.payload;
        }
    },

})

export const { customerSearchData, invoiceSearchData } = invoiceHistory_slice.actions

export default invoiceHistory_slice.reducer