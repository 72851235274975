import React, { useState } from "react";
import axios from "../../axios-util/customAxis";
// import styles from './InvoiceHistory.module.scss';
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import TranslucentCard from "../components/MaterialUI/Card/TranslucentCard";
//alert
import MaterialTable from "material-table";
import { useAlert } from "react-alert";

// bootstarp pagination

//reducer
import { useDispatch } from "react-redux";

const InvoiceHistory = () => {
  const alertS = useAlert();

  //reducer
  const dispatch = useDispatch();
  // const searchData = useSelector(
  //   (state) => state.invoiceHistory_slice.searchData
  // );

  const [searchData, setSearchData] = useState();
  // console.log(searchDataS);

  let history = useHistory();
  const tableRef = React.useRef();

  const [pageSize, setPageSize] = useState(10);
  const onSearchChange = (e) => {
    if (!e.target.value) tableRef.current.onQueryChange();
    setSearchData(e.target.value);
  };

  // const { SearchBar } = Search;

  const columns = [
    {
      field: "invoiceNumber",
      title: "Invoice No",
    },
    {
      field: "customerId.customerName",
      title: "Customer Name",
    },
    {
      field: "customerId.phone",
      title: "Phone",
    },
    {
      field: "invoiceDate",
      title: "Invoice Date",
    },
    {
      field: "totalAmount",
      title: "Amount",
    },
    {
      field: "totalDue",
      title: "Due",
    },
    {
      field: "isPaid",
      title: "Is Paid",
      render: (rowData) => {
        return rowData?.isPaid ? (
          <FontAwesomeIcon color="green" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon color="red" icon={faTimesCircle} />
        );
      },
    },
  ];

  // search call
  const loadSearchedData = (e) => {
    // sessionStorage.setItem("searchData", searchData);
    // sessionStorage.setItem("invoiceSearchData", searchData.invoiceSearchData);
    e.preventDefault();
    // loadPageItems(newDataToLoad.page, newDataToLoad.sizePerPage);
    tableRef.current.onQueryChange({ criteria: searchData });
  };

  const getData = async (query) => {
    console.log(query);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/paginate`;

    const requestBody = {
      pageN: query.page + 1,
      pageS: query.pageSize,
      searchData: searchData?.toString().trim(),
    };

    const resp = await axios.post(url, requestBody);
    let mainInvoiceData = [];

    if (resp.data.total > 0) {
      const isPaid = (total, paid) => {
        if (total - paid === 0) {
          return true;
        } else {
          return false;
        }
      };

      resp.data.items.map((invoiceData, index = 1) => {
        index++;
        mainInvoiceData.push({
          ...invoiceData,
          key: index,
          totalAmount: parseFloat(invoiceData.totalAmount).toFixed(2),
          totalDue: parseFloat(
            invoiceData.totalAmount - invoiceData.totalPaid
          ).toFixed(2),
          isPaid: isPaid(invoiceData.totalAmount, invoiceData.totalPaid),
          invoiceDate: moment.utc(invoiceData.createdAt).format("MM/DD/YYYY"),
        });
      });

      // setDataCount(resp.data.total);
      // setProducts(mainInvoiceData);

      // setLoading(false);
    } else {
      // setDataCount(0);
      // setProducts([]);
      // setLoading(false);
    }
    setPageSize(query.pageSize);
    return {
      data: mainInvoiceData,
      page: query.page,
      totalCount: resp.data.total,
      pageSize: query.pageSize,
    };
  };

  // if (loading) {
  //   return <h6>Loading...</h6>;
  // }

  return (
    <>
      <Helmet>
        <title>Invoice App - Invoice History</title>
      </Helmet>
      <div className="mt-2">
        <TranslucentCard
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            {/* {
                            !loading ? */}

            <div style={{ marginTop: "40px" }}>
              <Row className="justify-content-between align-items-center">
                <Col xs={8} md={6} lg={4} xl={5}>
                  {/* <SearchBar {...props.searchProps} /> */}
                  <div className="d-flex align-items-center w-100">
                    <form onSubmit={loadSearchedData}>
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control m-2"
                        name="invoiceSearchData"
                        value={searchData}
                        onChange={(e) => onSearchChange(e)}
                      />
                    </form>
                    {/* <input
                          type="text"
                          placeholder="search customer"
                          className="form-control m-2"
                          name="customerSearchData"
                          value={searchData.customerSearchData}
                          onChange={(e) => onSearchChange(e)}
                        /> */}
                    <button
                      className="btn btn-md btn-primary"
                      onClick={loadSearchedData}
                    >
                      Search
                    </button>
                  </div>
                </Col>

                <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                  <Button
                    variant="primary"
                    as={Link}
                    to={Routes.Invoice.path}
                    className="text-end"
                  >
                    New
                  </Button>
                </Col>
              </Row>

              <hr />
            </div>

            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => getData(query)}
              title={<h2>Invoices</h2>}
              options={{
                paging: true,
                pageSize: pageSize, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [5, 10, 25, 50, 100, 200], // rows selection options
                paginationPosition: "bottom",
                paginationType: "normal",
                search: false,
              }}
              onRowClick={(_, rowData) =>
                history.push("/invoice?id=" + rowData?._id)
              }
            />
            {/* <div className="float-end">
              <Paginations
                totalRecords={dataCount}
                pageLimit={newDataToLoad.sizePerPage}
                pageNeighbours={2}
                onPageChanged={onPageChanged}
                currentPage={pageNumber}
              />
            </div> */}
          </Card.Body>
        </TranslucentCard>
      </div>
    </>
  );
};

export default InvoiceHistory;
