import React, { useEffect, useState } from "react";
import axios from "../../../axios-util/customAxis";
import { Card, Button, Row, Col, Modal } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useHistory } from "react-router";
// import { Routes } from '../../../routes';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Helmet } from "react-helmet-async";

//alert
import { useAlert } from "react-alert";
import TranslucentCard from "../../components/MaterialUI/Card/TranslucentCard";

export default () => {
  const alert = useAlert();

  let history = useHistory();
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "item",
      text: "Item Name",
    },
    {
      dataField: "scope",
      text: "Scope",
    },
  ];
  const rowStyle = { padding: "5px 0", cursor: "pointer" };
  const options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    paginationSize: 5,
  };
  const [itemDB, setItemDB] = useState([]);
  const [show, setShow] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [editItemData, setEditItemData] = useState();
  const [itemToDelete, setItemToDelete] = useState("");
  const [paymentData, setPaymentData] = useState({
    item: "",
    user: "",
  });

  const getProfile = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`)
      .then((resp) => {
        // this.setState({['user']:resp.data});
        // console.log(resp.data);
        setPaymentData({ user: resp.data });
      })
      .catch((err) => {});
  };
  const loadItem = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/item/showitem`)
      .then((resp) => {
        setItemDB(resp.data);
        // if(resp.data.length > 0){
        //     resp.data.map((res) => {
        //         if(res.paymentItems.length > 0){
        //             res.paymentItems.map((re) => {
        //                 console.log(re);
        //             })
        //         }
        //     })
        // }
        // if(resp.data.paymentItems.length > 0){
        //     console.log(resp.data.paymentItems)
        // }
      })
      .catch((err) => {
        // alert("Could not fetch details");
        alert.error("Could not fetch details");
      });
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(row._id);
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/api/item/${row._id}`)
        .then((resp) => {
          if (resp?.data?.scope === "Global") {
            alert.error("Cannot edit Global master Item");
          } else {
            setEditItemData(resp?.data);
            setItemToDelete(resp?.data);
            setEditItem(true);
            setShow(true);
          }
        })
        .catch((err) => {
          // alert("Could not update Item!");
          alert.error("Could not update Item!");
        });
    },
  };
  const handleOpenModal = () => {
    setEditItem(false);
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleSaveModal = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/item/create/`,
        paymentData
      )
      .then((resp) => {
        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Item already exist");
            alert.error("Item already exist");
            setShow(false);
            getProfile();
          }
        } else {
          // alert("Item Added sucessfully");
          alert.success("Item Added sucessfully");
          setShow(false);
          getProfile();
        }

        loadItem();
      })
      .catch((err) => {
        setShow(false);
        // alert("Could not add Item!");
        alert.success("Could not add Item!");

        getProfile();
      });
  };
  const handleSaveModalUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/item/${editItemData._id}`,
        editItemData
      )
      .then((resp) => {
        setEditItemData("");
        setShow(false);

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Item already exist");
            alert.error("Item already exist");

            getProfile();
          }
        } else {
          // alert("Item Updated sucessfully");
          alert.success("Item Updated sucessfully");

          getProfile();
        }

        loadItem();
      })
      .catch((err) => {
        setEditItemData("");
        setShow(false);
        // alert("Could not update Item!");
        alert.error("Could not update Item!");

        getProfile();
      });
  };

  const handleItemDelete = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/item/delete`,
        itemToDelete
      );

      setShow(false);
      loadItem();

      alert.success("item deleted");
    } catch (err) {
      console.log(err);
      alert.error("Unable to Delete!");
    }
  };

  const onChangeText = (e) => {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };
  const onChangeTextUpdate = (e) => {
    setEditItemData({ ...editItemData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getProfile();
    loadItem();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoice App - Item Master</title>
      </Helmet>

      <div className="mt-2">
        <TranslucentCard
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="p-0">
            <h3>Item Master </h3>
            <ToolkitProvider
              keyField="_id"
              data={itemDB}
              columns={columns}
              search
            >
              {(props) => (
                <div style={{ marginTop: "20px" }}>
                  <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                      <SearchBar {...props.searchProps} />
                    </Col>

                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                      <Button
                        variant="primary"
                        onClick={handleOpenModal}
                        className="text-end"
                      >
                        New
                      </Button>
                    </Col>
                  </Row>

                  <hr />
                  <BootstrapTable
                    hover
                    bootstrap4={true}
                    rowStyle={rowStyle}
                    rowClasses="custom-row-class"
                    rowEvents={tableRowEvents}
                    bordered={false}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card.Body>
        </TranslucentCard>
      </div>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editItem ? "Edit" : "Add"} Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {editItem ? (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <input
                      type="text"
                      placeholder="Item Name"
                      name="item"
                      className="form-control h-100"
                      value={editItemData.item}
                      onChange={(e) => onChangeTextUpdate(e)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <input
                      type="text"
                      placeholder="Item Name"
                      name="item"
                      className="form-control h-100"
                      value={paymentData?.item}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {editItem ? (
            <>
              <Button variant="danger" onClick={handleItemDelete}>
                Delete Item
              </Button>
              <Button variant="primary" onClick={handleSaveModalUpdate}>
                Update Item
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleSaveModal}>
              Save Item
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
