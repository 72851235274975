import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faPhone,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Routes } from "../../routes";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { Box, Dialog, DialogTitle, TextField, Typography } from "@mui/material";
import { ReactComponent as MySvg } from "../JewelPay.svg";
import { useAlert } from "react-alert";
import { MyPhoneInput } from "../components/MaterialUI";

export default () => {
  const history = useHistory();
  const alert = useAlert();

  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    phone: "",
    businessName: "",
    businessAddress: "",
    password: "",
    cpassword: "",
  });

  useEffect(() => {
    if (history?.location?.state?.name) {
      setInputField(history?.location?.state);
    }
  }, [history?.location?.state]);

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    try {
      if (
        inputField &&
        Object.keys(inputField).find((key) => {
          if (
            !inputField[key]?.toString().trim() ||
            (key === "phone" && !(inputField[key]?.length > 6))
          ) {
            return alert.error("Invalid " + key);
          }
        })
      ) {
        return;
      }

      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/checkUserExist`,
        inputField
      );

      if (res?.data?.exists?.phone || res?.data?.exists?.email) {
        alert.error(res?.data?.message);
      } else {
        // Send OTP
        sessionStorage.setItem("userData", JSON.stringify(inputField));
        history.push({ pathname: "/phone-verification" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePhoneNumberChange = (value) => {
    setInputField({ ...inputField, phone: value });
  };

  const handleInput = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  //Phone Verification
  //  const phoneVerfication = (e) => {
  //      e.preventDefault()
  //      axios
  //          .get('/adm-reg', (req, res) => {
  //              client
  //                  .verify
  //                  .service(config.serviceID)
  //                  .verification
  //                  .create({
  //                      to: req.query.phonenumber,
  //                      channel: req.query.channel
  //                  })
  //                  .then((data) => {
  //                      res.status(200).send(data)
  //                  })
  //          })
  //  };
  //      const verify = (e) => {
  //          e.preventDefault()
  //          axios
  //              .get('/verify', (req, res) => {
  //                  client
  //                      .verify
  //                      .service(config.serviceID)
  //                      .verificationChecks
  //                      .create({
  //                          to: (req.query.phonenumber),
  //                          channel: req.query.code
  //                      })
  //                      .then((data) => {
  //                          res.status(200).send(data)
  //                      })
  //              })
  //      };

  return (
    <main>
      <section className="d-flex align-items-center justify-content-center mt-2">
        <Container className="d-block justify-content-center align-items-center">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  backgroundColor: "rgba(255, 255, 255, 0.31)",
                }}
                style={{
                  width: 525,
                  height: 60,
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  // onClick={() => history.push("/about")}
                >
                  ABOUT
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/pricing")}
                >
                  PRICING
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/adm-reg")}
                >
                  SIGN UP
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/")}
                >
                  LOG IN
                </Button>
              </Box>
            </div>
            <Box
              sx={{
                width: 523,
                height: "90vh",
                backgroundColor: "rgba(255, 255, 255, 0.28)",
                display: "block",
                marginTop: "2px",
                paddingTop: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "3rem",
                border: 2,
                borderColor: "#ffffff",
                borderRadius: 5,
              }}
              className="justify-content-center align-items-center"
            >
              <Card
                className="justify-content-center align-items-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.37)",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 491,
                  // height: 600,
                }}
              >
                <div>
                  <Box
                    sx={{
                      display: "grid",
                      backgroundColor: "rgba(255, 255, 255, 0.37)",
                      borderRadius: 5,
                      marginTop: "1rem",
                      height: 80,
                      width: 300,
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <MySvg />
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <Typography
                          className="d-flex justify-content-center align-items-center pt-3 "
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Jewel Pay
                        </Typography>
                      </div>
                    </div>
                  </Box>
                  <Form onSubmit={handleFormSubmission}>
                    <Form.Group id="name" className="mb-1">
                      <Form.Label>NAME</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faUser} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          name="name"
                          autoFocus
                          onChange={handleInput}
                          required
                          value={inputField?.name}
                          type="text"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="businessName" className="mb-1">
                      <Form.Label>BUSINESS NAME</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faEnvelope} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          name="businessName"
                          autoFocus
                          required
                          onChange={handleInput}
                          value={inputField?.businessName}
                          type="text"
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="businessAddress" className="mb-1">
                      <Form.Label>BUSINESS ADDRESS</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faEnvelope} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          name="businessAddress"
                          autoFocus
                          required
                          value={inputField?.businessAddress}
                          onChange={handleInput}
                          type="text"
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="email" className="mb-1">
                      <Form.Label>EMAIL</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faEnvelope} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          autoFocus
                          value={inputField?.email}
                          required
                          onChange={handleInput}
                          type="email"
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="phone" className="mb-1">
                      <Form.Label>PHONE</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faEnvelope} />*/}
                        </InputGroup.Text>
                        <MyPhoneInput
                          international
                          defaultCountry="US"
                          name="phone"
                          placeholder="phone"
                          value={inputField?.phone}
                          onChange={handlePhoneNumberChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group id="password" className="mb-1">
                      <Form.Label>CHOOSE PASSWORD</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faUnlockAlt} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          name="password"
                          required
                          value={inputField?.password}
                          onChange={handleInput}
                          type="password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-1">
                      <Form.Label>REPEAT PASSWORD</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="cpassword"
                          onChange={handleInput}
                          value={inputField?.cpassword}
                          required
                          type="password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-1">
                      {inputField.cpassword &&
                        inputField.password &&
                        inputField.cpassword !== inputField.password && (
                          <Form.Label>Password does not match!</Form.Label>
                        )}
                    </Form.Group>

                    <div className="text-center mt-1">
                      {/*{(inputField.cpassword===inputField.password) &&*/}
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: 150, height: 50, marginBottom: "30px" }}
                        disabled={
                          inputField?.cpassword !== inputField?.password
                        }
                        // onClick={() => history.push("/phone-verification")}
                      >
                        NEXT
                      </Button>
                    </div>
                  </Form>
                </div>
              </Card>

              {/* <div>
                <div className="text-center mt-1">
                  <span>
                    {" "}
                    Already signed up?
                    <Link to="/">
                      <h6>&nbsp;Log In</h6>
                    </Link>
                  </span>
                </div>
              </div> */}
            </Box>
          </Row>
        </Container>
      </section>
    </main>
  );
};
