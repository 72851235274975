import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MySvg } from "../JewelPay.svg";
import {
  faAngleLeft,
  faEnvelope,
  faPhone,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router";
import { Box, SvgIcon, Typography } from "@mui/material";
import { useAlert } from "react-alert";

export default (props) => {
  const history = useHistory();
  const { saveToken } = useToken();
  const [userData, setUserData] = useState({});
  const [otpValue, setOtpValue] = useState();
  const [errorText, setErrorText] = useState();
  const [disableTimer, setDisableTimer] = useState(30);
  const [disableOtpButton, setDisableOtpButton] = useState(false);

  const [loading, setLoading] = useState(false);

  const [otpSentText, setOtpSentText] = useState("Sending OTP: ");

  let userSessionData = sessionStorage.getItem("userData"),
    myInterval;
  userSessionData = userSessionData && JSON.parse(userSessionData);

  const disableOTPmilliseconds = 30000;

  const alert = useAlert();

  useEffect(() => {
    const stripeToken = new URLSearchParams(props.location.search).get(
      "stripeToken"
    );
    // http://localhost:3001/#/phone-verification?stripeToken=sk_test_51K7JmkSDxEmSu9AmLoUfTYn0VVWDNlIZM3smjnpsj8R26Vtlwhb2rbdINjcAHGH6ZoLRUxADF44FZN5Da2045svH00LzB7ABFu
    if (stripeToken && userSessionData?.email?.toString().trim()) {
      verifyPaymentAndRegister(stripeToken, userSessionData);
    } else {
      if (!userSessionData?.email?.toString().trim()) {
        history.push("/adm-reg");
      } else if (userSessionData?.email?.toString().trim()) {
        setUserData(userSessionData);
        sendOtp();
      }
    }
  }, [userSessionData?.name]);

  const sendOtp = async () => {
    try {
      if (userSessionData?.phone?.toString().trim()) {
        setDisableOtpButton(true);

        myInterval = setInterval(() => {
          return setDisableTimer((prev) => prev - 1);
        }, 1000);

        setTimeout(() => {
          setDisableOtpButton(false);
          clearInterval(myInterval);
          setDisableTimer(30);
        }, disableOTPmilliseconds); //this will enable button after 30 seconds you can change time here.
      }

      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/send_otp`,
        userSessionData
      );

      setOtpSentText("Otp Sent: ");
    } catch (err) {
      console.log(err);
      setOtpSentText("Unable to send the OTP: ");
    }
  };

  const verifyPaymentAndRegister = async (stripeToken, userSessionData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/verifyPaymentAndRegister`,
        {
          stripeToken: stripeToken,
          userData: {
            ...userSessionData,
            paymentsInformation: [
              {
                date: new Date(),
                amount: process.env.REACT_APP_STRIPE_AMOUNT,
                currency: process.env.REACT_APP_STRIPE_CURRENCY,
              },
            ],
          },
        }
      );

      if (res?.data?.auth && res?.data?.token) {
        saveToken(res.data);
        sessionStorage.clear("userData");

        const hostName = window.location.hostname,
          port = window.location.port;

        window.location.assign(
          window.location.protocol +
            (hostName === "localhost" ? hostName + ":" + port : hostName) +
            "/#/home"
        );
      } else {
        alert.error(res?.data?.message || "Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOtpAndPay = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (!otpValue?.toString()?.trim()) {
        return alert.error("Enter OTP!");
      }

      let registrationData = userData || {};
      registrationData.inputOtp = otpValue;

      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/verifyOtp`,
        registrationData
      );

      if (res.data.auth) {
        window.location.assign(process.env.REACT_APP_STRIPE_PAYMENT_URL);
      } else {
        setErrorText(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center justify-content-center mt-2">
        <Container className="d-block justify-content-center align-items-center">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  backgroundColor: "rgba(255, 255, 255, 0.31)",
                }}
                style={{
                  width: 525,
                  height: 60,
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  // onClick={() => history.push("/about")}
                >
                  ABOUT
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  // onClick={() => history.push("/pricing")}
                >
                  PRICING
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  //   onClick={() => history.push("/adm-reg")}
                >
                  SIGN UP
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/")}
                >
                  LOG IN
                </Button>
              </Box>
            </div>
            <Box
              sx={{
                width: 523,
                height: 720,
                backgroundColor: "rgba(255, 255, 255, 0.28)",
                display: "block",
                marginTop: "2px",
                paddingTop: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "3rem",
                border: 2,
                borderColor: "#ffffff",
                borderRadius: 5,
              }}
              className="justify-content-center align-items-center"
            >
              <Card
                className="justify-content-center align-items-center"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.37)",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 491,
                  height: 600,
                }}
              >
                <div>
                  <Box
                    sx={{
                      display: "grid",
                      backgroundColor: "rgba(255, 255, 255, 0.37)",
                      borderRadius: 5,
                      marginTop: "1rem",
                      height: 80,
                      width: 300,
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <MySvg />
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <Typography
                          className="d-flex justify-content-center align-items-center pt-3 "
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Jewel Pay
                        </Typography>
                      </div>
                    </div>
                  </Box>
                  {/* //onSubmit={} */}
                  <div>
                    <Typography
                      className="d-flex justify-content-center align-items-center pt-3 "
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      {otpSentText + userData?.phone}
                    </Typography>
                  </div>

                  <Form onSubmit={verifyOtpAndPay}>
                    <Form.Group id="confirmPassword" className="mb-1">
                      <Form.Label>Enter OTP</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="otp"
                          onChange={(e) => setOtpValue(e.target.value)}
                          required
                          type="number"
                        />
                      </InputGroup>
                    </Form.Group>
                    {/*{(inputField.cpassword!==inputField.password) &&<Form.Label>Password does not match!</Form.Label>}*/}

                    <div className="text-center mt-1">
                      {/*{(inputField.cpassword===inputField.password) &&*/}
                      <Button
                        variant="primary"
                        style={{ width: 400, height: 50, marginTop: "20px" }}
                        type="submit"
                        disabled={!otpValue?.toString().trim() || loading}
                      >
                        Verify and Checkout to Payment
                      </Button>
                    </div>

                    {errorText && (
                      <div
                        className="text-center mt-1"
                        style={{ color: "red" }}
                      >
                        OTP incorrect!
                      </div>
                    )}
                  </Form>

                  <div className="text-center mt-1">
                    <button
                      onClick={() => sendOtp()}
                      disabled={disableOtpButton}
                    >
                      {"Resend OTP" +
                        (disableOtpButton
                          ? " in " + disableTimer + " seconds"
                          : "")}
                    </button>
                  </div>

                  <div className="text-center mt-1">
                    {/*{(inputField.cpassword===inputField.password) &&*/}
                    <Button
                      variant="primary"
                      style={{ width: 150, height: 50, marginTop: "20px" }}
                      onClick={() =>
                        history.push({
                          pathname: "/adm-reg",
                          state: userSessionData,
                        })
                      }
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Card>
              <div>
                <div className="text-center mt-1">
                  <span>
                    {" "}
                    Already signed up?
                    <Link to="/">
                      <h6>&nbsp;Log In</h6>
                    </Link>
                  </span>
                </div>
              </div>
            </Box>
          </Row>
        </Container>
      </section>
    </main>
  );
};
