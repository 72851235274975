import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MySvg } from "../JewelPay.svg";
import {
  faAngleLeft,
  faEnvelope,
  faPhone,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import HomePageContainer from "./HomePageContainer";
import TranslucentCard from "../components/MaterialUI/Card/TranslucentCard";

export default () => {
  const history = useHistory();
  const { saveToken } = useToken();
  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });
  const handleInput = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/users/login`, loginData)
      .then((res) => {
        saveToken(res.data);
        window.location = "/#/home";
        // history.push("/home");
      })
      .catch((error) => {
        // console.log(error);
        alert("Invalid username or password OR user logged in");
      });
  };
  return (
    <HomePageContainer>
      <TranslucentCard sx={{ width: "90%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TranslucentCard sx={{ width: "100%", height: "20vh" }}>
              <p className="text-center h3">One Time Cost</p>
              <p className="text-center h4">$14.99</p>
            </TranslucentCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <TranslucentCard sx={{ width: "100%", minHeight: "20vh" }}>
              <p className="text-center h3">Monthly Basis</p>
              <p className="text-center h4">$14.9/mo</p>{" "}
              <p className="text-center h5">$178.8/year</p>
            </TranslucentCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <TranslucentCard sx={{ width: "100%", minHeight: "20vh" }}>
              <p className="text-center h3">Yearly Basis</p>
              <p className="text-center h4">$11.9/mo</p>
              <p className="text-center h5">$142.8/year</p>
            </TranslucentCard>
          </Grid>
        </Grid>
      </TranslucentCard>
      <Button
        className="my-3 text-black"
        style={{
          background:
            "linear-gradient(180deg, rgba(108, 155, 233, 0.3213) 0%, rgba(108, 155, 233, 0) 100%)",
        }}
      >
        FREE TRIAL
      </Button>
    </HomePageContainer>
  );
};
