import React, { useEffect, useState } from "react";
import axios from "../../../axios-util/customAxis";
// import styles from './PaymentMaster.module.scss';
import { Card, Button, Row, Col, Modal } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useHistory } from "react-router";
// import { Routes } from '../../../routes';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Helmet } from "react-helmet-async";

//alert
import { useAlert } from "react-alert";

export default () => {
  const alert = useAlert();

  let history = useHistory();
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "melting",
      text: "melting",
    },
  ];
  const rowStyle = { padding: "5px 0", cursor: "pointer" };
  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    paginationSize: 5,
  };
  const [paymentDB, setPaymentDB] = useState([]);
  const [show, setShow] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const [editPaymentData, setEditPaymentData] = useState();
  const [meltingDelete, setMeltingDelete] = useState("");
  const [paymentData, setPaymentData] = useState({
    melting: "",
    user: "",
  });

  const getProfile = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/users/me`)
      .then((resp) => {
        // this.setState({['user']:resp.data});
        setPaymentData({ user: resp.data });
      })
      .catch((err) => {});
  };
  const loadPayment = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/melting/showmelting`)
      .then((resp) => {
        setPaymentDB(resp.data);
        // if(resp.data.length > 0){
        //     resp.data.map((res) => {
        //         if(res.paymentItems.length > 0){
        //             res.paymentItems.map((re) => {
        //                 console.log(re);
        //             })
        //         }
        //     })
        // }
        // if(resp.data.paymentItems.length > 0){
        //     console.log(resp.data.paymentItems)
        // }
      })
      .catch((err) => {
        // alert("Could not fetch details");
        alert.error("Could not fetch details");
      });
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(row._id);
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/api/melting/${row._id}`)
        .then((resp) => {
          setEditPaymentData(resp.data);
          setMeltingDelete(resp.data._id);
          setEditPayment(true);
          setShow(true);
        })
        .catch((err) => {
          // alert("Could not update Melting!");
          alert.error("Could not update Melting!");
        });
    },
  };

  const handleMeltingDelete = () => {
    let data = {
      id: meltingDelete,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/melting/delete`, data)
      .then((resp) => {
        setShow(false);
        loadPayment();
        // alert("melting deleted");
        alert.success("melting deleted");
      })
      .catch((err) => {
        // alert("something went wrong");
        alert.error("something went wrong");
      });
  };

  const handleOpenModal = () => {
    setEditPayment(false);
    setShow(true);
  };
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleSaveModal = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/melting/create/`,
        paymentData
      )
      .then((resp) => {
        setShow(false);

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Melting already exist");
            alert.error("Melting already exist");

            getProfile();
          }
        } else {
          // alert("Melting Added sucessfully");
          alert.success("Melting Added sucessfully");

          getProfile();
        }

        loadPayment();
      })
      .catch((err) => {
        setShow(false);
        // alert("Could not add Melting!");
        alert.error("Could not add Melting!");

        getProfile();
      });
  };
  const handleSaveModalUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/api/melting/${editPaymentData._id}`,
        editPaymentData
      )
      .then((resp) => {
        setEditPaymentData("");
        setShow(false);

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Melting already exist");
            alert.error("Melting already exist");

            getProfile();
          }
        } else {
          // alert("Melting Added sucessfully");
          alert.success("Melting Updated sucessfully");

          getProfile();
        }

        loadPayment();
      })
      .catch((err) => {
        setEditPaymentData("");
        setShow(false);
        // alert("Could not update Melting!");
        alert.error("Could not update Melting!");

        getProfile();
      });
  };
  const onChangeText = (e) => {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };
  const onChangeTextUpdate = (e) => {
    setEditPaymentData({ ...editPaymentData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getProfile();
    loadPayment();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoice App - Melting Master</title>
      </Helmet>

      <div className="mt-2">
        <Card
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <h5>Melting Master </h5>
            <ToolkitProvider
              keyField="_id"
              data={paymentDB}
              columns={columns}
              search
            >
              {(props) => (
                <div style={{ marginTop: "20px" }}>
                  <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                      <SearchBar {...props.searchProps} />
                    </Col>

                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                      <Button
                        variant="primary"
                        onClick={handleOpenModal}
                        className="text-end"
                      >
                        New
                      </Button>
                    </Col>
                  </Row>

                  <hr />
                  <BootstrapTable
                    hover
                    bootstrap4={true}
                    rowStyle={rowStyle}
                    rowClasses="custom-row-class"
                    rowEvents={tableRowEvents}
                    bordered={false}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card.Body>
        </Card>
      </div>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editPayment ? "Edit" : "Add"} Melting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {editPayment ? (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <input
                      type="text"
                      placeholder="Melting"
                      name="melting"
                      className="form-control h-100"
                      value={editPaymentData.melting}
                      onChange={(e) => onChangeTextUpdate(e)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <input
                      type="text"
                      placeholder="Melting"
                      name="melting"
                      className="form-control h-100"
                      value={paymentData.melting}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {editPayment ? (
            <>
              <Button variant="danger" onClick={handleMeltingDelete}>
                Delete Melting
              </Button>
              <Button variant="primary" onClick={handleSaveModalUpdate}>
                Update Melting
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleSaveModal}>
              Save Melting
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
