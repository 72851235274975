import axios from "axios";
import moment from "moment-timezone";

const goldApiKey = "goldapi-33urttkxxladwb-io";

const goldAPIHeaders = new Headers();
goldAPIHeaders.append("x-access-token", goldApiKey);
goldAPIHeaders.append("Content-Type", "application/json");

export const dateFormatter = (date) => {
  try {
    date = moment(date).format("yyyyMMDD");
  } catch (err) {
    console.log(err);
  }

  // date format should be => 20211225
  return date;
};

export const fetchLiveGoldRate = async (live = false, changedDate) => {
  let response = {};

  try {
    const metalSymbol = "XAU",
      currencyCode = "USD",
      currencySymbol = "$"; // currencySymbol to display in ui

    const requestOptions = {
      method: "GET",
      headers: {
        "x-access-token": goldApiKey,
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    if (live) {
      const url = `https://www.goldapi.io/api/${metalSymbol}/${currencyCode}`;

      response = await axios.get(url, requestOptions);

      if (response?.data?.price) {
        response = response.data;
        response.price = response.price;
        response.priceUnit = currencySymbol;
      }
    } else {
      let fetchDate = changedDate || new Date();

      fetchDate = dateFormatter(fetchDate);

      let url = `https://www.goldapi.io/api/${metalSymbol}/${currencyCode}/${fetchDate}`;

      response = await axios.get(url, requestOptions);

      if (!response?.data?.price) {
        url = `https://www.goldapi.io/api/${metalSymbol}/${currencyCode}`;
        // fetchDate = new Date(Date.now() - 864e5 * i); // yesterday's date
        response = await axios.get(url, requestOptions);
      }

      response = response?.data || {};
      response.priceUnit = currencySymbol;
    }
  } catch (err) {
    console.log(err);
  }

  return response;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
