import React, { Component } from "react";
import styles from "./Invoice2.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LineItems2 from "./LineItems2";
import PaymentItems2 from "./PaymentItems2";

import { v4 as uuidv4 } from "uuid";
import axios from "../../axios-util/customAxis";
// import { useHistory } from 'react-router-dom';
// import CompanyLogo from "../../assets/img/technologies/logo.png";
import { Helmet } from "react-helmet-async";

// select
import CreatableSelect from "react-select/creatable";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
} from "@themesberg/react-bootstrap";

import CustomerIcon from "../common/icons/customerIcon.svg";
// html to print invoice
import InvoiceToPrint from "./InvoiceToPrint";

// alert
import { withAlert } from "react-alert";
import moment from "moment-timezone";
import { dataURLtoFile, fetchLiveGoldRate } from "./api";
import { goldPurityChart } from "../../globalData/invoice";
import {
  createFilterOptions,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  MyAutocomplete,
  MyToolTip,
  MyDatePicker,
  MyTextField,
  MyPhoneInput,
} from "../components/MaterialUI";
import TranslucentCard from "../components/MaterialUI/Card/TranslucentCard";
import printJS from "print-js";
import MySignatureCanvas from "../components/MaterialUI/Signature/MySignatureCanvas";
import {
  MyDialogContent,
  MyDialogTitle,
} from "../components/MaterialUI/Dialog/MyDialog";
import Preloader from "../../components/Preloader";

const filter = createFilterOptions();

export const returnMasterItem = "RETURN";
export const discountMasterPayment = "DISCOUNT";

class Invoice2 extends Component {
  locale = "en-US";
  currency = "USD";
  invoiceId = null;
  state = {
    loading: false,
    goldPurityType: "karat",
    invoiceSignature: null,
    openSign: false,

    taxRate: 0.0,
    goldRate: 1,
    lineItems: [
      {
        id: uuidv4(), // react-beautiful-dnd unique key
        name: "",
        quantity: 0,
        price: 0.0,
        itemId: "",
        purity: { percentage: 58.3, karat: "14" },
        labor: 0,
      },
    ],
    paymentItems: [
      {
        id: uuidv4(), // react-beautiful-dnd unique key
        paymentMode: "",
        amount: 0,
        paymentDate: this.getTodaysDate(null),
        paymentTypeId: "",
        description: "",
      },
    ],
    invoiceNumber: "",
    customerName: "",
    customerAddress: "",
    phone: "",
    isPaid: false,
    // invoiceDate: ,
    myFlag: true,
    isFormValid: true,
    user: "",
    customerDB: [],
    paymentDB: [],
    itemDB: [],
    customerData: [],
    newCustomerModal: false,
    customerId: null,
    newCustomerData: {
      customerName: "",
      phone: "",
      customerAddress: {
        street: "",
        city: "",
        zip: "",
        state: "",
        country: "",
      },
      fullAddress: "",
      shippingName: "",
      sortBy: "",
      jewelryLabor: "",
      popup: false,
      user: "",
      prohibit: false,
      code: "",
    },
    disableCustomerDetailsInput: false,
    itemSelected: false,
    paymentSelected: false,
    isPrinting: false,
  };

  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth <= 820 });
  };

  constructor(props) {
    super(props);

    this.setTrimmedDataUrl = this.setTrimmedDataUrl.bind(this);
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  setTrimmedDataUrl(value) {
    this.setState({ invoiceSignature: value });
  }

  handleFormValidation() {
    let errors = [];
    if (!this.state["customerName"]) {
      errors.push("Cutomer name can't be empty");
    }
    if (!this.state["phone"]) {
      errors.push("Phone number can't be empty");
    }
    this.state.lineItems.forEach((item, i) => {
      if (!item["name"]) {
        errors.push(`Item name at row ${i + 1} can't be empty`);
      }
      if (item?.purity?.percentage < 0 || item?.purity?.percentage > 100) {
        errors.push(`Invalid Purity at row ${i + 1}!`);
      }
    });
    if (errors.length) {
      // this.setState({ ['isFormValid']: false });
    } else {
      this.setState({ ["isFormValid"]: true });
    }
  }

  async setLiveGoldRate(userProfile, fetchChangedDate) {
    try {
      const preferredUpdates = userProfile?.invoiceSetting?.goldRateUpdates;

      if (preferredUpdates?.live) {
        localStorage.removeItem("goldenData");

        const fetchLiveRate = await fetchLiveGoldRate(true);
        if (fetchLiveRate?.price) {
          this.setState({ goldRate: fetchLiveRate });
        }
      } else if (fetchChangedDate) {
        const fetchChangedDateRate = await fetchLiveGoldRate(
          false,
          fetchChangedDate
        );
        if (fetchChangedDateRate?.price) {
          this.setState({ goldRate: fetchChangedDateRate });
          this.props.alert.success("Gold Rate updated successfully!");
        } else {
          this.props.alert.error("Unable to update Gold rate");
        }
      } else {
        const goldenData =
          localStorage.getItem("goldenData") &&
          JSON.parse(localStorage.getItem("goldenData"));

        const lastFetchDate = goldenData?.date && moment(goldenData?.date);

        const todaysDate = moment(this.getTodaysDate()?.replaceAll("-", ""));
        let difference;

        if (lastFetchDate && todaysDate) {
          difference = lastFetchDate.diff(todaysDate, "days");
        }

        if (goldenData?.price && difference === 0) {
          this.setState({ goldRate: goldenData });
        } else {
          const fetchLiveRate = await fetchLiveGoldRate();
          if (fetchLiveRate?.price) {
            this.setState({ goldRate: fetchLiveRate });
            localStorage.setItem("goldenData", JSON.stringify(fetchLiveRate));
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange();

    this.handleFormValidation();
    let invoiceId = new URLSearchParams(this.props.location.search).get("id");

    if (!invoiceId) {
      this.setState({});
    } else {
      this.invoiceId = invoiceId;
    }

    this.getProfile(invoiceId);
    this.getCustomers();
    this.getPaymentMethods();
    this.getItems();
    // this.getInvoiceReport();
  }

  getProfile = async (invoiceId) => {
    let userProfile = {};
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/me`
      );

      if (resp?.data) {
        userProfile = resp.data;

        this.setState({ ["user"]: userProfile });
        this.setState({
          ["newCustomerData"]: {
            ...this.state.newCustomerData,
            ["user"]: userProfile,
          },
        });

        if (invoiceId) {
          this.getInvoiceDetailsbyId(invoiceId);
        } else {
          this.getInvoiceNumber();
          this.setLiveGoldRate(userProfile);
        }
      }
    } catch (err) {
      console.log(err);
      this.props.alert.error("Something went wrong!");
    }
    return userProfile;
  };
  getCustomers = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`)
      .then((resp) => {
        this.setState({ ["customerData"]: resp.data });
        let data = [];
        resp.data.map((res) => {
          data.push({
            ...res,
            value: res.customerName,
            label: res.customerName,
            id: res._id,
          });
        });

        this.setState({ ["customerDB"]: data });
      })
      .catch((err) => {});
  };

  getPaymentMethods = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/paymentmaster/showpayment`
      )
      .then((resp) => {
        this.setState({ ["paymentDB"]: resp?.data || [] });
      })
      .catch((err) => {});
  };

  shareReport = async () => {
    try {
      this.setState({ loading: true });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/shareReport`,
        {
          data: this.state,
          userProfile:
            this?.state.user.profilePic &&
            `${process.env.REACT_APP_API_ENDPOINT}/img/${this.state?.user.profilePic}`,
          invoiceTotal: this.formatCurrency(this.calcGrandTotal()),
          totalGold: this.calcTotalGoldWt(),
          amountPaid: this.formatCurrency(this.calcAmtPaid()),
          amountDue: this.formatCurrency(this.calcBalance()),
        }
      );
      //
      const phoneNum = this.state.whatsAppNumber?.split("+");

      window.open(
        `https://api.whatsapp.com/send/?phone=${
          phoneNum?.[1] ? phoneNum[1] : ""
        }&text=Hi ${this.state.customerId?.customerName}, 
          \nInvoice Number: ${this.state.invoiceNumber}. 
          \nReport: ${response?.data?.fileUrl}`
      );
    } catch (err) {
      console.log(err);
    }

    this.setState({ loading: false });
  };

  getInvoiceReport = () => {
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/invoiceReport`, {
        data: this.state,
        userProfile:
          this?.state.user.profilePic &&
          `${process.env.REACT_APP_API_ENDPOINT}/img/${this.state?.user.profilePic}`,
        invoiceTotal: this.formatCurrency(this.calcGrandTotal()),
        totalGold: this.calcTotalGoldWt(),
        amountPaid: this.formatCurrency(this.calcAmtPaid()),
        amountDue: this.formatCurrency(this.calcBalance()),
      })
      .then((response) => {
        // handle success
        console.log(response.data);
        printJS({
          printable: response.data.data,
          type: "pdf",
          base64: true,
          honorMarginPadding: false,
        });
        // const a = document.createElement("a");
        // a.href = `data:application/pdf;base64,${response.data.data}`;
        // // a.target = "_blank";
        // a.download = "a.pdf";
        // a.click();
        // console.log(response);

        var file = dataURLtoFile(
          `data:application/pdf;base64,${response.data.data}`,
          "hello.pdf"
        );

        const dataUrl = window.URL.createObjectURL(file);

        // Open the window
        const pdfWindow = window.open(dataUrl);

        // Call print on it
        pdfWindow.print();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(() => {
        // always executed
        this.setState({ loading: false });
      });
  };

  checkNewItemId = async (itemId) => {
    try {
      if (
        this.state.itemDB &&
        !this.state.itemDB.find((dbItem) => dbItem?.item === itemId?.item) &&
        itemId?.item
      ) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/item/create`,
          itemId
        );

        this.props.alert.success("Item Added sucessfully");
        this.setState({
          ["itemDB"]: [...(this.state.itemDB || []), res?.data] || [],
        });
      }
    } catch (err) {
      console.log(err);
      this.props.alert.error("Failed to create Item");
    }
  };

  getItems = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/item/showitem`)
      .then((resp) => {
        this.setState({ ["itemDB"]: resp?.data || [] });
      })
      .catch((err) => {});
  };

  getInvoiceDetailsbyId = (invoiceId) => {
    this.setState({ ["disableCustomerDetailsInput"]: true });
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/${invoiceId}`)
      .then((response) => {
        const { user } = this.state;
        // console.log(response.data);
        this.setState({ ...response["data"], ["user"]: user });

        // console.log(response.data.customerName);
        // axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/customer/showcustomer`).then((resp)=>{
        //     this.setState({['customerData']: resp.data});
        //     let data = [];
        //     resp.data.map((res) => {
        //         data.push({value: res.customerName, label: res.customerName, id: res._id});
        //     })
        //     this.setState({['customerDB']:data});
        //   }).catch((err)=>{
        // });

        // this.setState({ ['invoiceDate']: this.getTodaysDate(response['data']['invoiceDate']) });

        // calling the API
        this.getCustomers();
        this.getPaymentMethods();
        this.getItems();
      })
      .catch((error) => {
        this.getInvoiceNumber();
        // console.log(error);
      });
  };
  getInvoiceNumber = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/number`)
      .then((response) => {
        // handle success
        this.setState({ ["invoiceNumber"]: response["data"]["invoiceNumber"] });
        //   console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(() => {
        // always executed
      });
  };

  handlePurityTypeChange = (event) => {
    this.setState({ goldPurityType: event.target.value });
    if (event.target.value === "karat") {
      let lineItems = [...this.state.lineItems];
      lineItems = lineItems.map((item) => ({
        ...item,
        purity: goldPurityChart[0],
      }));

      this.setState({ lineItems: lineItems });
    }
  };

  getTodaysDate(mydate) {
    let invdate = mydate ? new Date(mydate) : new Date();
    let formatedDate = invdate.toISOString().substr(0, 10);
    return formatedDate;
  }

  handleInvDateChange = (value) => {
    //   console.log(new Date().toLocaleDateString());
    this.setState({ invoiceDate: value });
    this.handleFormValidation();

    // this.setLiveGoldRate(this.state.user, value);
  };

  handleIsPaid = (event) => {
    this.setState({ [event.target.name]: !this.state.isPaid });
    this.handleFormValidation();
  };

  handleGoldRateChange = (event) => {
    this.setState({
      goldRate: {
        ...this.state.goldRate,
        price: event.target.value,
      },
    });
  };

  handleInvoiceChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.handleFormValidation();
  };

  handleLineItemChange = (elementIndex) => (event) => {
    try {
      let lineItems = this.state.lineItems || [],
        item = lineItems[elementIndex];

      const value = event.target.value,
        name = event.target.name;

      if (name === "purity.percentage") {
        item = {
          ...item,
          purity: {
            percentage: value,
          },
        };
      } else if (name === "purity") {
        item = {
          ...item,
          purity: value,
        };
      } else if (name === "itemId") {
        let itemId = {};
        if (typeof value === "string") {
          itemId = {
            item: value,
          };
        } else if (value?.inputValue) {
          itemId = { item: value?.inputValue };
        } else {
          itemId = value;
        }

        // this.setState({ ["itemId"]: itemId });
        this.checkNewItemId(itemId);

        item[name] = itemId;
      } else {
        item[name] = value;
      }

      if (this.state.oldDataGroup === 1) {
        let itemTotal = Number(
          (Number(item?.price) * Number(item?.quantity))?.toFixed(2)
        );

        if (item?.itemId?.item === returnMasterItem) {
          itemTotal = -Math.abs(itemTotal);
        }

        item = {
          ...item,
          amount: itemTotal,
        };
      } else {
        const goldPrice = this.state.goldRate?.price
            ? Number(
                (Number(this.state.goldRate?.price) / 31.1).toFixed(2) //31.1034768
              ) // converting price from per troy to per gram
            : 0,
          purityPercentage = Number(Number(item?.purity?.percentage) / 100);

        let itemLabor = item?.labor && Number(item?.labor),
          itemPrice = item?.price && Number(item?.price);

        if (name === "price") {
          itemLabor = Number(
            Number(
              (itemPrice ? itemPrice : 0) - goldPrice * purityPercentage
            )?.toFixed(2)
          );
        } else {
          itemPrice = Number(
            (
              goldPrice * purityPercentage +
              (itemLabor ? itemLabor : 0)
            )?.toFixed(2)
          );
        }

        let itemTotal = Number(
          (Number(itemPrice ? itemPrice : 0) * Number(item?.quantity))?.toFixed(
            2
          )
        );

        if (item?.itemId?.item === returnMasterItem) {
          itemTotal = -Math.abs(itemTotal);
        }

        item = {
          ...item,
          price: itemPrice,
          labor: itemLabor,
          amount: itemTotal,
        };
      }

      lineItems[elementIndex] = item;
      this.setState({ lineItems });
      this.handleFormValidation();
      // debugger;
      // this.setState({ ["itemSelected"]: true });
    } catch (err) {
      console.log(err);
    }
  };

  handlePaymentItemChange = (elementIndex) => (event) => {
    const value = event.target.value,
      name = event.target.name;

    let newPaymentItems = [...this.state.paymentItems];
    newPaymentItems[elementIndex] = {
      ...newPaymentItems[elementIndex],
      [name]: value,
    };

    this.setState({ paymentItems: newPaymentItems });
    this.handleFormValidation();
    // this.setState({ ["paymentSelected"]: true });
  };

  handleAddLineItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: this.state.lineItems.concat([
        {
          id: uuidv4(),
          name: "",
          quantity: 0,
          price: 0.0,
          purity: { percentage: 58.3, karat: "14" },
          labor: 0,
        },
      ]),
    });
    this.handleFormValidation();
  };
  handleAddPaymentItem = (event) => {
    this.setState({
      // use optimistic uuid for drag drop; in a production app this could be a database id
      paymentItems: this.state.paymentItems.concat([
        {
          id: uuidv4(),
          paymentMode: "",
          amount: 0,
          paymentDate: this.getTodaysDate(null),
        },
      ]),
    });
    this.handleFormValidation();
  };

  handleRemoveLineItem = (elementIndex) => (event) => {
    this.setState({
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };
  handleRemovePaymentItem = (elementIndex) => (event) => {
    this.setState({
      paymentItems: this.state.paymentItems.filter((item, i) => {
        return elementIndex !== i;
      }),
    });
  };

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      lineItems: newLineItems,
    });
  };
  handleNewInvoice = () => {
    // window.location = "/#/invoice";
    // this.context.router.history.push("/invoice");

    this.props.history.push("/invoice");
    window.location.reload(true);
  };

  handleFocusSelect = (event) => {
    event.target.select();
  };

  handlePayButtonClick = () => {
    // this.setState({ myFlag: false });
    // let add_item = document.getElementById('add_item');
    // let pay_add_item = document.getElementById('pay_add_item');
    // // let delete_button = document.getElementsByClassName('deleteItem');
    // let pay_delete_button = document.getElementById('pay_delete_button');
    // add_item.style["display"] = "none";
    // pay_add_item.style["display"] = "none";
    // let lineItems = document.getElementById('lineItem');
    // lineItems.style.marginBottom = '0px';
    // let payItem = document.getElementById('payItem');
    // payItem.style.marginBottom = '0px';
    // let customerAddress = document.getElementById('customerAddress');
    // let cAddress = document.getElementById('innerCustDiv');
    // customerAddress.style.border = 'none';
    // cAddress.style.marginLeft = "-20px"

    // setTimeout(() => { window.print() }, 1000);
    // setTimeout(() => {
    //     this.setState({ myFlag: true });
    //     add_item.style["display"] = "initial";
    //     pay_add_item.style["display"] = "initial";
    //     lineItems.style.marginBottom = '80px';
    //     payItem.style.marginBottom = '80px';
    //     customerAddress.style.border = '1px';
    //     cAddress.style.marginLeft = "0px";

    // }, 1000);
    this.getInvoiceReport();
    // new code
    // this.setState({ isPrinting: true });
    // setTimeout(() => {
    //     let el = document.getElementById('iv_n');
    //     el.style["font-type"] = "'Andika', sans-serif";
    // }, 500);
  };

  handleSaveButtonClick = async () => {
    let invoiceId = this.invoiceId;

    if (
      !(
        this.state.lineItems &&
        this.state.lineItems.find((item) => item?.itemId)
      )
    ) {
      // return alert("Item cannot be empty");
      this.props.alert.error("Item cannot be empty");
      return;
    }
    if (!(this.state.customerId?._id && this.state.customerId?.customerName)) {
      this.props.alert.error("Customer cannot be empty");
      return;
    }

    this.setState({ loading: true });
    //Update
    try {
      if (invoiceId) {
        let resp = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/${invoiceId}`,
          this.state
        );

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Invoice already exist");
            this.props.alert.error("Invoice already exist");
          }
        } else {
          // alert("Invoice updated sucessfully");

          this.props.history.push("/invoice?id=" + resp?.data?._id);
          window.location.reload();

          this.props.alert.success("Invoice updated sucessfully");
        }
      } else {
        let resp = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/invoice`,
          this.state
        );

        if (resp.data.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Invoice already exist");
            this.props.alert.error("Invoice already exist");
          }
        } else {
          // alert("Invoice added sucessfully");

          this.props.history.push("/invoice?id=" + resp?.data?._id);
          window.location.reload();

          this.props.alert.success("Invoice added sucessfully");
        }
      }
    } catch (err) {
      console.log(err);
      this.props.alert.error("Could not update invoice!");
    }

    this.setState({ loading: false });
  };

  formatCurrency = (amount) => {
    return new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100);
  };

  calcLineItemsTotal = () => {
    return this.state.lineItems.reduce(
      (prev, cur) => prev + Number(cur?.amount ? cur?.amount : 0),
      0
    );
  };

  calcTaxTotal = () => {
    return this.calcLineItemsTotal() * (this.state.taxRate / 100);
  };

  calcGrandTotal = () => {
    return this.calcLineItemsTotal() + this.calcTaxTotal();
  };

  calcTotalGoldWt = () => {
    return this.precise(
      this.state.lineItems.reduce((prev, cur) => {
        let itemQuantity = cur.quantity;
        if (cur?.itemId?.item === returnMasterItem) {
          itemQuantity = -Math.abs(itemQuantity);
        }

        return parseFloat(parseFloat(prev) + parseFloat(itemQuantity)).toFixed(
          2
        );
      }, 0)
    );
  };
  precise(x) {
    return Number.parseFloat(x).toPrecision();
    // gold weight not round off
    // add toPrecision(4) for round off weight
  }
  calcAmtPaid = () => {
    return this.state.paymentItems.reduce(
      (prev, cur) =>
        parseFloat(prev ? prev : 0) + parseFloat(cur.amount ? cur.amount : 0),
      0
    );
  };

  calcBalance = () => {
    const paymentTotal = this.state.paymentItems.reduce(
      (prev, cur) =>
        parseFloat(prev ? prev : 0) + parseFloat(cur.amount ? cur.amount : 0),
      0
    );
    const total = this.calcGrandTotal();
    return total - paymentTotal;
  };

  checkNewCustomer = (customerName) => {
    if (customerName) {
      // console.log(customerName);

      let checkValue = (value, arr) => {
        var status = false;

        for (var i = 0; i < arr.length; i++) {
          var name = arr[i].value;
          if (name == value) {
            status = true;
            break;
          }
        }
        return status;
      };

      if (!checkValue(customerName, this.state.customerDB)) {
        this.setState({ ["newCustomerModal"]: true });
        this.setState({
          ["newCustomerData"]: {
            ...this.state.newCustomerData,
            ["customerName"]: customerName,
          },
        });
      }
    }
  };

  handleChange = (newValue) => {
    let customer = {};
    if (typeof newValue === "string") {
      customer = {
        customerName: newValue,
      };
    } else if (newValue && newValue.inputValue) {
      customer = { customerName: newValue?.inputValue };
    } else {
      customer = newValue;
    }
    this.setState({ ["customerId"]: customer });
    this.checkNewCustomer(customer?.customerName);
  };

  // close new customer modal

  handleClose = () => this.setState({ ["newCustomerModal"]: false });

  // handle new customer inputs

  onChangeText = (e) => {
    this.setState({
      ["newCustomerData"]: {
        ...this.state.newCustomerData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeAddressValue = (e) => {
    let data = { ...this.state.newCustomerData };
    data.customerAddress[e.target.name] = e.target.value;
    this.setState({ ["newCustomerData"]: data });
  };

  // save new customer

  saveNewCustomer = () => {
    if (!this.state.newCustomerData?.customerName?.toString().trim()) {
      return this.props.alert.error("Customer name is empty");
    }
    let mainDat = {
      ...this.state.newCustomerData,
      fullAddress: `${this.state.newCustomerData.customerAddress.street} ${this.state.newCustomerData.customerAddress.city} ${this.state.newCustomerData.customerAddress.zip} ${this.state.newCustomerData.customerAddress.state}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/customer/create/`,
        mainDat
      )
      .then((resp) => {
        if (resp?.data?.message) {
          if (
            resp.data.message.code === 11000 ||
            resp.data.message.name === "ValidationError"
          ) {
            // alert("Customer already exist");
            window.alert("Customer already exist");
            // this.setState({ ["newCustomerModal"]: false });
            // this.setState({ ["disableCustomerDetailsInput"]: true });
          }
        } else {
          // here i have to put the customer ID on our local state and also add customer details for showing the ui
          // console.log(resp.data._id);
          this.setState({ ["customerId"]: resp?.data || {} });

          this.setState({
            ["newCustomerData"]: {
              customerName: "",
              phone: "",
              customerAddress: {
                street: "",
                city: "",
                zip: "",
                state: "",
                country: "",
              },
              fullAddress: "",
              shippingName: "",
              sortBy: "",
              jewelryLabor: "",
              popup: false,
              user: "",
              prohibit: false,
              code: "",
            },
          });

          // alert("Customer Added sucessfully");
          this.props.alert.success("Customer Added sucessfully");

          this.setState({ ["newCustomerModal"]: false });
          this.getCustomers();
        }
        // loadCustomer();
      })
      .catch((err) => {
        // this.setState({
        //   ["newCustomerData"]: {
        //     customerName: "",
        //     phone: "",
        //     customerAddress: {
        //       street: "",
        //       city: "",
        //       zip: "",
        //       state: "",
        //       country: "",
        //     },
        //     fullAddress: "",
        //     shippingName: "",
        //     sortBy: "",
        //     jewelryLabor: "",
        //     popup: false,
        //     user: "",
        //     prohibit: false,
        //     code: "",
        //   },
        // });
        // alert("Could not add customer!");
        window.alert("Could not add customer!");
      });
  };

  render = () => {
    const { myFlag, isFormValid, user, disableCustomerDetailsInput } =
      this.state;

    // console.log(this.showCustomer(this.invoiceId))
    return (
      <>
        <Helmet>
          <title>Invoice App - Invoice</title>
        </Helmet>

        <Preloader show={this.state.loading} />

        {!this.state.isPrinting ? (
          <TranslucentCard>
            <TranslucentCard>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Card
                    variant="outlined"
                    className={`h-100 p-2 ${styles.card}`}
                  >
                    <Stack spacing={2} className="p-2">
                      <MyTextField
                        name="invoiceNumber"
                        value={this.state.invoiceNumber}
                        onChange={this.handleInvoiceChange}
                        label={<b>Invoice</b>}
                      />

                      <MyDatePicker
                        className="form-control d-lg-w-50 d-md-w-100"
                        name="invoiceDate"
                        value={this.state.invoiceDate}
                        onChange={this.handleInvDateChange}
                        style={componentStyles.inputFieldStyles}
                        label={<b>Invoice Date</b>}
                      />

                      <MyTextField
                        name="goldRate.price"
                        value={
                          this.state.goldRate?.price
                            ? this.state.goldRate?.price
                            : ""
                        }
                        onChange={this.handleGoldRateChange}
                        label={<b>Gold Rate</b>}
                      />
                    </Stack>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card
                    variant="outlined"
                    className={`h-100 p-2 ${styles.card}`}
                  >
                    <Grid container spacing={2} className="h-100">
                      <Grid item xs={12} sm={6}>
                        <Stack spacing={2}>
                          <img
                            src={CustomerIcon}
                            alt="Customer"
                            style={{
                              height: "100px",
                              width: "100px",
                              pointerEvents: "none",
                            }}
                            className="rounded-circle border border-success mx-auto"
                          />
                          <div></div>
                          <MyAutocomplete
                            onChange={(_, newValue) =>
                              this.handleChange(newValue)
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option?.customerName
                              );
                              if (
                                inputValue?.toString().trim() &&
                                !isExisting
                              ) {
                                filtered.push({
                                  inputValue,
                                  customerName: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={this.state.customerDB || []}
                            value={this.state.customerId}
                            label={"Customer"}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option?.inputValue) {
                                return option?.inputValue;
                              }
                              // Regular option
                              return option?.customerName;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option?.customerName}</li>
                            )}
                            getOptionSelected={(option, value) =>
                              option?.customerName === value?.customerName
                            }
                            freeSolo
                            sx={{ width: "100%" }}
                          />
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          textAlign: "center",
                          margin: "auto",
                          wordBreak: "break-word",
                        }}
                      >
                        <div>
                          <span>
                            {this.state.customerId?.customerAddress?.street}
                          </span>
                          <br />
                          <span>
                            {this.state.customerId?.customerAddress?.city}
                          </span>
                          <br />
                          <span>
                            {this.state.customerId?.customerAddress?.state}-
                            {this.state.customerId?.customerAddress?.zip}
                          </span>
                          <br />
                          <span>
                            {this.state.customerId?.customerAddress?.country}
                          </span>
                          <br />
                          <span>{this.state.customerId?.phone}</span>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={2} container justifyContent={"flex-start"}>
                  <Stack spacing={2} className="h-100 w-100">
                    {myFlag && (
                      <Button
                        className={styles.create_new_button}
                        // className="btn btn-md btn-outline-primary m-2"
                        style={{ width: "100%" }}
                        onClick={this.handleNewInvoice}
                      >
                        CREATE NEW
                      </Button>
                    )}

                    <Card
                      variant="outlined"
                      style={{ height: "inherit" }}
                      className="p-2"
                    >
                      <div className="h6 text-center">Customer Notes</div>
                      <hr className="m-0" />
                      <div>{this.state.customerId?.popupnotes}</div>
                    </Card>
                  </Stack>
                </Grid>
              </Grid>
            </TranslucentCard>

            <Card variant="outlined" className={`${styles.card} h-100 mt-4`}>
              <Grid container>
                <Grid item xs={11} className="table-responsive">
                  {/* <div className="border rounded d-flex flex-row justify-content-around">
                    <div>Item</div>
                    <div>Gold Wt.</div>
                    <div>Purity</div>
                    <div>Labor</div>
                    <div>Rate</div>
                    <div>Total</div>
                  </div>
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>Larry</td>
                        <td> the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table> */}
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>

              <LineItems2
                items={this.state.lineItems}
                goldPurityType={this.state.goldPurityType}
                handlePurityTypeChange={this.handlePurityTypeChange}
                currencyFormatter={this.formatCurrency}
                addHandler={this.handleAddLineItem}
                changeHandler={this.handleLineItemChange}
                goldRate={this.state.goldRate}
                focusHandler={this.handleFocusSelect}
                deleteHandler={this.handleRemoveLineItem}
                reorderHandler={this.handleReorderLineItems}
                itemsDB={this.state.itemDB}
                oldDataGroup={this.state.oldDataGroup}
              />

              <PaymentItems2
                items={this.state.paymentItems}
                currencyFormatter={this.formatCurrency}
                addHandler={this.handleAddPaymentItem}
                changeHandler={this.handlePaymentItemChange}
                focusHandler={this.handleFocusSelect}
                deleteHandler={this.handleRemovePaymentItem}
                reorderHandler={this.handleReorderLineItems}
                paymentsMethod={this.state.paymentDB}
              />

              {/* <div className={styles.totalContainer}>
                                <form>
                                </form>
                                <form>
                                    <div className={styles.valueTable}>

                                        <div className={styles.row}>
                                            <div className={styles.label}>Total Gold Wt.</div>
                                            <div className={`${styles.value} text-end`}>{this.calcTotalGoldWt()}</div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.label}>Total Due</div>
                                            <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcGrandTotal())}</div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.label}>Amount Payed</div>
                                            <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcAmtPaid())}</div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.label}>Balance</div>
                                            <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcBalance())}</div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}

              <Grid container>
                <Grid item container xs={4} md={4} direction="column">
                  <FooterItem
                    label="Total Gold"
                    value={`${this.calcTotalGoldWt()} (g)`}
                  />

                  <FooterItem
                    label="Invoice Total"
                    value={this.formatCurrency(this.calcGrandTotal())}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  md={4}
                  direction="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {isFormValid && (
                    <>
                      {myFlag && (
                        <Paper
                          sx={{
                            display: {
                              lg: "none",
                              xl: "none",
                              md: "block",
                              sm: "block",
                              xs: "block",
                            },
                            background: "rgba(255, 255, 255, 0.37) !important",
                            marginBottom: "15px",
                          }}
                        >
                          {this.state.openSign ? (
                            <Dialog
                              open={this.state.openSign}
                              // onClose={() => this.setState({ openSign: false })}
                            >
                              <MyDialogTitle
                                onClose={() =>
                                  this.setState({ openSign: false })
                                }
                              >
                                Signature
                              </MyDialogTitle>
                              <MyDialogContent>
                                <MySignatureCanvas
                                  trimmedDataURL={this.state.invoiceSignature}
                                  setTrimmedDataUrl={this.setTrimmedDataUrl}
                                  openSign={this.state.openSign}
                                  setOpenSign={() =>
                                    this.setState({ openSign: false })
                                  }
                                />
                              </MyDialogContent>
                            </Dialog>
                          ) : (
                            <img
                              className={styles.sigImage}
                              src={this.state.invoiceSignature}
                            />
                          )}
                          <h6 onClick={() => this.setState({ openSign: true })}>
                            SIGN
                          </h6>
                        </Paper>
                      )}
                      {myFlag && (
                        <button
                          style={{
                            height: "5vh",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          className={`w-50 btn btn-sm btn-outline-primary ${styles.save_button} h2`}
                          onClick={this.handleSaveButtonClick}
                        >
                          Save
                        </button>
                      )}

                      {myFlag && (
                        <MyToolTip title={"Save Invoice To PRINT"}>
                          <button
                            style={{
                              height: "5vh",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                            className={`w-50 btn btn-sm btn-outline-primary ${styles.print_button} h2`}
                            onClick={() => {
                              if (!this.invoiceId) {
                                this.props.alert.error("Save Invoice To PRINT");
                              } else {
                                this.handlePayButtonClick();
                              }
                            }}

                            // disabled={!this.invoiceId}
                          >
                            Print
                          </button>
                        </MyToolTip>
                      )}

                      {myFlag && (
                        <MyToolTip title={"Save Invoice To Share"}>
                          <button
                            style={{
                              height: "5vh",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                            className={`w-50 btn btn-sm btn-outline-primary ${styles.whatsapp_button} h2`}
                            onClick={() => {
                              if (!this.invoiceId) {
                                this.props.alert.error("Save Invoice To Share");
                              } else {
                                this.setState({ openReportShare: true });
                                this.setState({
                                  whatsAppNumber: this.state.customerId?.phone,
                                });
                              }
                            }}

                            // disabled={!this.invoiceId}
                          >
                            {this.state.isMobile ? (
                              <WhatsAppIcon />
                            ) : (
                              <>
                                WhatsApp <WhatsAppIcon />
                              </>
                            )}
                          </button>
                        </MyToolTip>
                      )}
                    </>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  xs={4}
                  md={4}
                  direction="column"
                  alignItems={"flex-end"}
                >
                  <FooterItem
                    label="Amount Paid"
                    value={this.formatCurrency(this.calcAmtPaid())}
                  />

                  <FooterItem
                    label="Balance Amount"
                    value={this.formatCurrency(this.calcBalance())}
                  />
                </Grid>
              </Grid>
            </Card>
          </TranslucentCard>
        ) : (
          <InvoiceToPrint
            data={this.state}
            currencyFormatter={this.formatCurrency}
            calcTotalGoldWt={this.calcTotalGoldWt}
            calcGrandTotal={this.calcGrandTotal}
            calcAmtPaid={this.calcAmtPaid}
            calcBalance={this.calcBalance}
          />
        )}
        {/* <InvoiceToPrint data={this.ssstate} currencyFormatter={this.formatCurrency} calcTotalGoldWt={this.calcTotalGoldWt} calcGrandTotal={this.calcGrandTotal} calcAmtPaid={this.calcAmtPaid} calcBalance={this.calcBalance} /> */}

        {/* confirm the new customer modal  */}
        {this.state.openReportShare && (
          <Dialog
            open={this.state.openReportShare}
            fullWidth
            // onClose={() => this.setState({ openSign: false })}
          >
            <MyDialogTitle
              onClose={() => this.setState({ openReportShare: false })}
            >
              <Typography
                variant="h4"
                component="div"
                style={{
                  color: "green",
                  margin: "16px",
                }}
              >
                WhatsApp <WhatsAppIcon />
              </Typography>
            </MyDialogTitle>
            <MyDialogContent>
              <Form.Group
                id="phone"
                className="mb-1"
                style={{ margin: "16px" }}
              >
                <InputGroup>
                  <MyPhoneInput
                    international
                    defaultCountry="US"
                    placeholder="WhatsApp Share Number"
                    value={this.state.whatsAppNumber}
                    onChange={(value) =>
                      this.setState({ whatsAppNumber: value })
                    }
                  />
                </InputGroup>
              </Form.Group>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  justify="flex-end"
                  spacing={2}
                  style={{ margin: "16px" }}
                >
                  <Grid item xs>
                    <Button
                      // className={styles.create_new_button}
                      // className="btn btn-md btn-outline-primary m-2"
                      style={{ width: "100%" }}
                      onClick={() => this.setState({ openReportShare: false })}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      className={styles.create_new_button}
                      // className="btn btn-md btn-outline-primary m-2"
                      style={{ width: "100%" }}
                      onClick={this.shareReport}
                    >
                      Share
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MyDialogContent>
          </Dialog>
        )}

        <Modal show={this.state.newCustomerModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.newCustomerData?.customerName} is new customer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="text-center">
                            <h6>Please save customer details.</h6>
                        </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                  <input
                    type="text"
                    placeholder="Customer Name"
                    name="customerName"
                    className="form-control h-100"
                    value={this.state.newCustomerData.customerName}
                    onChange={(e) => this.onChangeText(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <MyPhoneInput
                    international
                    defaultCountry="US"
                    name="phone"
                    placeholder="Customer Number"
                    value={this.state.newCustomerData?.phone}
                    onChange={(value) =>
                      this.setState({
                        newCustomerData: {
                          ...this.state.newCustomerData,
                          phone: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                <div className="col-sm-6 col-md-7 col-lg-7 col-xl-7">
                  <input
                    placeholder="Street"
                    name="street"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.street}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <input
                    placeholder="City"
                    name="city"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.city}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="Zip"
                    name="zip"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.zip}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="State"
                    name="state"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.state}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pt-2">
                  <input
                    placeholder="Country"
                    name="country"
                    className="form-control w-100"
                    value={this.state.newCustomerData.customerAddress.country}
                    onChange={(e) => this.onChangeAddressValue(e)}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row pt-2">
                <input
                  placeholder="Customer Notes"
                  name="popupnotes"
                  className="form-control w-100"
                  value={this.state.newCustomerData.popupnotes}
                  onChange={(e) => this.onChangeText(e)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.saveNewCustomer}>
              Save Customer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
}

const componentStyles = {
  inputFieldStyles: {
    height: "30px",
    width: "100%",
    backgroundColor: "white",
  },
};

const FooterItem = ({ label, value }) => {
  return (
    <Grid
      className="border rounded mb-2 w-100 bg-white"
      alignItems={"center"}
      item
      container
      style={{ height: "12vh", padding: 0 }}
    >
      <Grid item xs={12} md={4} className="h6 ps-2">
        {label}
      </Grid>

      <Grid item xs={12} md={8} className={`h2 ${styles.footer_text}`}>
        {value}
      </Grid>
    </Grid>
  );
};

export default withAlert()(Invoice2);
