import React, { useEffect, useState } from "react";
import axios from "../../../axios-util/customAxis";
import { Card, Row, Col } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
// import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";

//alert
import { useAlert } from "react-alert";

export default () => {
  const alert = useAlert();

  // let history = useHistory();
  let { customerNameP } = useParams();

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "invoiceNumber",
      text: "Invoice No",
    },
    {
      dataField: "customerId.customerName",
      text: "Customer Name",
    },
    {
      dataField: "customerId.phone",
      text: "Phone",
    },
    {
      dataField: "invoiceDate",
      text: "Invoice Date",
    },
    {
      dataField: "totalAmount",
      text: "Amount",
    },
    {
      dataField: "totalDue",
      text: "Due",
    },
    {
      dataField: "isPaid",
      text: "Is Paid",
      formatter: (cell, row) => {
        return cell ? (
          <FontAwesomeIcon color="green" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon color="red" icon={faTimesCircle} />
        );
      },
    },
  ];

  const rowStyle = { padding: "5px 0", cursor: "pointer" };
  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    alwaysShowAllBtns: true,
    paginationSize: 5,
  };
  const [customerDB, setCustomerDB] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);

  // payment name check
  const paymentNameCheck = (paymentD) => {
    // console.log(paymentD);
    // console.log(allPaymentMethods);
    if (allPaymentMethods) {
      // console.log(allPaymentMethods);
      let returnData = "";
      allPaymentMethods.map((pd) => {
        if (paymentD.paymentTypeId === pd.id) {
          returnData = pd.label;
        }
      });
      return returnData;
    }
  };

  const loadCustomer = async (customerNameP) => {
    // /invoicereportbycustomer/:customerName/:customerPhone/:customerAddress
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/invr/invoicereportbycustomer/${customerNameP}`
      );

      if (resp.data.length > 0) {
        let mainD = [];
        const isPaid = (total, paid) => {
          if (total - paid === 0) {
            return true;
          } else {
            return false;
          }
        };

        resp?.data &&
          resp.data.map((invoiceData, index = 1) => {
            index++;
            mainD.push({
              ...invoiceData,
              key: index,
              totalAmount: parseFloat(invoiceData.totalAmount).toFixed(2),
              totalDue: parseFloat(
                invoiceData.totalAmount - invoiceData.totalPaid
              ).toFixed(2),
              isPaid: isPaid(invoiceData.totalAmount, invoiceData.totalPaid),
              invoiceDate: moment
                .utc(invoiceData.createdAt)
                .format("MM/DD/YYYY"),
            });
          });

        setCustomerDB(mainD);
        // console.log(mainD);
      }
    } catch (err) {
      console.log(err);
      alert.error("Could not fetch details");
    }
    // setCustomerDB(resp.data);
  };

  const loadPaymentMethods = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/paymentmaster/showpayment`
      )
      .then((resp) => {
        let data = [];
        Promise.all(
          resp.data.map((res) => {
            data.push({
              value: res.paymentModeName,
              label: res.paymentModeName,
              id: res._id,
            });
          })
        )
          .then(() => {
            setAllPaymentMethods(data);
          })
          .then(() => {
            loadCustomer(customerNameP);
          });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoice App - Customer Report</title>
      </Helmet>

      <div className="mt-2">
        <Card
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <h5>Customer Payment History</h5>
            <ToolkitProvider
              keyField="_id"
              data={customerDB}
              columns={columns}
              search
            >
              {(props) => (
                <div style={{ marginTop: "20px" }}>
                  <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                      <SearchBar {...props.searchProps} />
                    </Col>

                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                      {/* <Button variant="primary" onClick={handleOpenModal} className="text-end">
                                                New
                                            </Button> */}
                    </Col>
                  </Row>

                  <hr />
                  <BootstrapTable
                    hover
                    bootstrap4={true}
                    rowStyle={rowStyle}
                    rowClasses="custom-row-class"
                    bordered={false}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
