import React from "react";
import { TextField } from "@mui/material";

const MyTextField = (props) => {
  return (
    <TextField
      id="outlined-basic"
      label="Outlined"
      variant="outlined"
      style={props.style || { width: "100%" }}
      {...props}
    />
  );
};

export default MyTextField;
