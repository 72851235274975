import React, { useState, useEffect } from "react";
import "./invoiceToPrint.css";
import arrow from "../../extra/arrow.svg";
import moment from "moment-timezone";

const InvoiceToPrint = ({
  data,
  currencyFormatter,
  calcTotalGoldWt,
  calcGrandTotal,
  calcAmtPaid,
  calcBalance,
  itemNameFind,
}) => {
  const [isItem, setIsItem] = useState(true);
  const [isPayment, setIsPayment] = useState(true);
  const [newItemName, setNewItem] = useState([]);

  const dateFormator = (dateS) => {
    let date = new Date(data?.invoiceDate);
    let monthIndex = date.getMonth();
    let dates = date.getDate();
    let year = date.getFullYear();

    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return moment(data?.invoiceDate).format("MMMM, DD, YYYY");
  };

  useEffect(() => {
    if (data?.paymentItems.length < 1) {
      setIsPayment(false);
    }
    dateFormator();
  }, []);

  return (
    <>
      {/* new invoice Design  */}

      <div class="invoice-box">
        <table cellpadding="0" cellspacing="0">
          <td colspan="5">
            <div className="top">
              <div class="title">
                {data?.user.profilePic ? (
                  <img
                    src={`${process.env.REACT_APP_API_ENDPOINT}/img/${data?.user.profilePic}`}
                    className="companyLogo"
                  />
                ) : (
                  <div className="logoBox">
                    {data?.user?.address?.companyName}
                  </div>
                )}
              </div>
              <div className="inv ">
                <span className="iv_n"> Invoice #{data?.invoiceNumber}</span>
                <br />
                <span className="iv_date">
                  {moment(data?.invoiceDate).format("MMMM, DD, YYYY")}
                  <br />
                </span>
                <span className="iv_date">
                  {`Gold Price: ${data?.goldRate?.price}`}
                  <br />
                </span>
              </div>
            </div>
          </td>

          <tr class="information">
            <td colspan="5">
              <div class="">
                <div className="invoiceCusM">
                  <div className="invoiceCus_bg">
                    <div className="invoice_S">
                      <div className="invoice_I">
                        <h4 className="invoiceCus">
                          {data?.user?.address?.companyName}
                        </h4>
                        <span className="invoiceCus_details">
                          {data?.user?.address?.street}
                          <br />
                          {data?.user?.address?.city}
                          <br />
                          {data?.user?.address?.state}-
                          {data?.user?.address?.zip}
                          <br />
                          <span className="phoneBold">
                            {data?.user?.address?.telephone}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="invoice_S">
                      <div className="invoice_I">
                        <img src={arrow} className="arrow_l" />
                      </div>
                    </div>
                    <div className="invoice_S">
                      <div className="invoice_I">
                        <h4 className="invoiceCus">
                          {data?.customerId?.customerName}
                        </h4>
                        <span className="invoiceCus_details">
                          {data?.customerId?.customerAddress?.street}
                          <br />
                          {data?.customerId?.customerAddress?.city}
                          <br />
                          {data?.customerId?.customerAddress?.state}-
                          {data?.customerId?.customerAddress?.zip}
                          <br />
                          <span className="phoneBold">
                            {data?.customerId?.phone}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <hr />

          {/* <td className="table_border"> */}

          {/* <span> */}

          {isItem ? (
            <>
              <div className="headingT">
                PURCHASE
                <div className="line3" />
              </div>

              <br />

              <tr class="headings">
                <td className="td_first">ITEM</td>
                <td></td>
                <td>GOLD WEIGHT</td>
                <td>PRICE</td>
                <td>TOTAL</td>
              </tr>

              {data?.lineItems.map((id) => (
                <tr class="details">
                  <td className="td_first td_firstData">
                    <font face="Andika" color="#555454">
                      {id?.itemId?.item}
                    </font>
                  </td>
                  <td></td>
                  <td>
                    <font face="Andika" color="#555454">
                      {id.quantity} g
                    </font>
                  </td>
                  <td>
                    <font face="Andika" color="#555454">
                      ${id.price}
                    </font>
                  </td>
                  <td>
                    <font face="Andika" color="#555454">
                      {currencyFormatter(id.price * id.quantity)}
                    </font>
                  </td>
                </tr>
              ))}
            </>
          ) : null}

          <br />

          {data?.paymentItems?.length > 0 ? (
            <>
              <div className="headingTp">
                PAYMENT
                <div className="line3" />
              </div>

              <br />

              <tr class="headings">
                <td className="td_first">METHOD</td>
                <td></td>
                <td colSpan="2">AMOUNT</td>
                <td>DATE</td>
              </tr>
              {data?.paymentItems.map((pm) => (
                <tr class="details">
                  <td className="td_first td_firstData">
                    <font face="Andika" color="#555454">
                      {pm?.paymentTypeId?.paymentModeName}
                    </font>
                  </td>
                  <td></td>
                  <td colSpan="2">
                    <font face="Andika" color="#555454">
                      ${pm.amount}
                    </font>
                  </td>
                  <td>
                    <font face="Andika" color="#555454">
                      {dateFormator(pm.paymentDate)}
                    </font>
                  </td>
                </tr>
              ))}
            </>
          ) : null}

          {/* </td> */}
          {/* </span> */}
        </table>

        <div
          className="mt-5"
          style={{ position: "fixed", bottom: 0, zIndex: 5 }}
          id="footer"
        >
          <div className="center">
            <div className="bottom_report">
              <div className="text-center">
                <span className="bottom_report_heading">INVOICE TOTAL</span>
                <div className="line2" />
                <span className="bottom_text">
                  {currencyFormatter(calcGrandTotal())}
                </span>
              </div>
              <div className="text-center">
                <span className="bottom_report_heading">TOTAL GOLD WT.</span>
                <div className="line2" />
                <span className="bottom_text">{calcTotalGoldWt()} g</span>
              </div>
              <div className="text-center">
                <span className="bottom_report_heading">AMOUNT PAID</span>
                <div className="line2" />
                <span className="bottom_text">
                  {currencyFormatter(calcAmtPaid())}
                </span>
              </div>
              <div className="text-center">
                <span className="bottom_report_heading">AMOUNT DUE</span>
                <div className="line2" />
                <span className="bottom_text">
                  {currencyFormatter(calcBalance())}
                </span>
              </div>
            </div>
          </div>

          <div className="bottom_note">
            <hr className="line" />
            <br />
            <div className="footer_text">
              All Sales are final except for merchandise with manufacturer
              defects, in which case the buyer must notify the seller AND
              receive authorisation to return (for credit only) or exchange
              within 5 business days of the invoice. Payment is COD unless
              otherwise agreed upon by the seller and buyer PRIOR to delivery of
              goods. The merchandise described here in is delivered at the price
              listed, no exceptions. In the event the seller obtains an attorney
              for collection of the amount due under the terms of this.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceToPrint;
