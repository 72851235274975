import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "../../../Invoice/Invoice2.module.scss";

export default function MyAutocomplete(props) {
  const { options, label, value, multiple, InputProps = {} } = props;
  return (
    <Autocomplete
      //   disablePortal
      id="combo-box-demo"
      {...props}
      className={
        props.color === "red"
          ? `form-control ${styles.redAutocomplete}`
          : "form-control"
      }
      value={multiple ? (value ? value : []) : value ? value : null}
      options={options}
      style={{ width: "100%", color: "red" }}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          label={label}
          variant={props.variant || "outlined"}
          style={{
            width: "100%",
          }}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            disableUnderline: true,
          }}
        />
      )}
      {...props}
    />
  );
}
