import React, { useEffect, useState, useCallback } from "react";
import axios from "../../../axios-util/customAxis";
import { Card, Button, Row, Col, Modal } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Helmet } from "react-helmet-async";
import TranslucentCard from "../../components/MaterialUI/Card/TranslucentCard";

//alert
import { useAlert } from "react-alert";

// pagination
import Paginations from "../../components/Pagination_s";

export default () => {
  const alert = useAlert();

  let history = useHistory();

  const [customerDB, setCustomerDB] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [newDataToLoad, setNewDataToLoad] = useState({
    page: 1,
    sizePerPage: 10,
  });

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "customerName",
      text: "Customer Name",
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
    },
    {
      dataField: "totalPaid",
      text: "Total Paid Amount",
    },
    {
      dataField: "totalDue",
      text: "Total Due Amount",
    },
  ];
  const rowStyle = { padding: "5px 0", cursor: "pointer" };

  // const options = {
  //     // pageStartIndex: 0,
  //     sizePerPage: 10,
  //     hideSizePerPage: true,
  //     // hidePageListOnlyOnePage: true,
  //     alwaysShowAllBtns: true,
  //     paginationSize: 5
  // };

  // const loadCustomer = () => {
  //     axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/invoice/invoicereport/`).then((resp) => {

  //         if (resp.data.length > 0) {

  //             let mainD = [];
  //             resp.data.map((res) => {
  //                 mainD.push({ customerId: res.customerId, customerName: res._id.custname, totalAmount: res.totalAmount, totalPaid: res.totalPaid, totalDue: (res.totalAmount - res.totalPaid), address: res.address, phone: res.phone })
  //             });
  //             setCustomerDB(mainD);
  //         }

  //     }).catch((err) => {
  //         alert.error("Could not fetch details");
  //     });
  // }

  const loadPageItems = (pageN, pageS) => {
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/invoice/invoicereport/${pageN}/${pageS}`
      )
      .then((resp) => {
        setLoading(false);
        if (resp.data.total > 0) {
          // console.log(resp.data);

          let mainInvoiceData = [];

          resp.data.items.map((res, index = 1) => {
            index++;
            mainInvoiceData.push({
              customerId: res.customerId,
              key: index,
              customerName: res._id.custname,
              totalAmount: parseFloat(res.totalAmount).toFixed(2),
              totalPaid: parseFloat(res.totalPaid).toFixed(2),
              totalDue: parseFloat(res.totalAmount - res.totalPaid).toFixed(2),
              address: res.address,
              phone: res.phone,
            });
          });

          setDataCount(resp.data.total);

          setCustomerDB(mainInvoiceData);

          setLoading(false);
        }
      })
      .catch((err) => {
        alert.show("Could not fetch details");
        setLoading(false);
      });
  };

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setPageNumber(page);
      loadPageItems(page, newDataToLoad.sizePerPage);
    },
    [setPageNumber]
  );

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      let path = `/onecustomerreport/${row.customerId}`;
      history.push(path);
    },
  };

  useEffect(() => {
    // loadCustomer();
    loadPageItems(newDataToLoad.page, newDataToLoad.sizePerPage);
  }, []);

  if (loading) {
    return <h6>Loading...</h6>;
  }
  return (
    <>
      <Helmet>
        <title>Invoice App - Customer Report</title>
      </Helmet>

      <div className="mt-2">
        <TranslucentCard
          border="primary"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <h5>Customer Report </h5>

            {!loading ? (
              <ToolkitProvider
                keyField="_id"
                data={customerDB}
                columns={columns}
                search
              >
                {(props) => (
                  <div style={{ marginTop: "20px" }}>
                    <Row className="justify-content-between align-items-center">
                      <Col xs={8} md={6} lg={3} xl={4}>
                        <SearchBar {...props.searchProps} />
                      </Col>

                      <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        {/* <Button variant="primary" onClick={handleOpenModal} className="text-end">
                                                New
                                            </Button> */}
                      </Col>
                    </Row>

                    <hr />
                    <BootstrapTable
                      hover
                      bootstrap4={true}
                      rowStyle={rowStyle}
                      rowClasses="custom-row-class"
                      rowEvents={tableRowEvents}
                      bordered={false}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            ) : null}

            <div className="float-end">
              <Paginations
                totalRecords={dataCount}
                pageLimit={newDataToLoad.sizePerPage}
                pageNeighbours={2}
                onPageChanged={onPageChanged}
                currentPage={pageNumber}
              />
            </div>
          </Card.Body>
        </TranslucentCard>
      </div>
    </>
  );
};
