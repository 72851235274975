import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdCancel as DeleteIcon, MdOutlineCancel } from "react-icons/md";
import styles from "./LineItem2.module.scss";
import Select from "react-select";
import TableCell from "@mui/material/TableCell";
import { goldPurityChart } from "../../globalData/invoice";
import { MyAutocomplete } from "../components/MaterialUI";
import { createFilterOptions } from "@mui/material";
import { returnMasterItem } from "./Invoice2";
const filter = createFilterOptions();

class LineItem2 extends Component {
  // customStyles = {
  //     option: (provided, state) => ({
  //         ...provided,
  //         borderBottom: '1px dotted pink',
  //         color: 'blue',
  //         // padding: 20,
  //     }),
  //     control: () => ({
  //         // none of react-select's styles are passed to <Control />
  //         // width: 200,
  //     }),
  //     singleValue: (provided, state) => {
  //         const opacity = state.isDisabled ? 0.5 : 1;
  //         const transition = 'opacity 300ms';

  //         return { ...provided, opacity, transition };
  //     }
  // }

  render = () => {
    const {
      index,
      name,
      itemId,
      goldPurityType,
      goldRate,
      purity,
      labor,
      quantity,
      price,
      itemsDB,
      hideLaborAndPurity,
      amount,
    } = this.props;
    const colWidth = 100;

    // console.log(itemsDB);

    return (
      <>
        {/* <TableCell>{index + 1}</TableCell> */}
        {/* <div><input name="name" type="text" value={name} onChange={this.props.changeHandler(index)} /></div> */}

        <TableCell>
          <MyAutocomplete
            name="itemId"
            key="itemId"
            value={itemId}
            variant="standard"
            options={itemsDB || []}
            onChange={(event, value) => {
              event = {
                target: {
                  name: "itemId",
                  value: value,
                },
              };
              this.props.changeHandler(index)(event);
            }}
            getOptionSelected={(option, value) => option?.item === value?.item}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option?.item
              );
              if (inputValue?.toString().trim() && !isExisting) {
                filtered.push({
                  inputValue,
                  item: `Create "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            color={itemId?.item === returnMasterItem ? "red" : "black"}
            id="free-solo-with-text-demo"
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option?.inputValue) {
                return option?.inputValue;
              }
              // Regular option
              return option?.item;
            }}
            renderOption={(props, option) => <li {...props}>{option?.item}</li>}
            freeSolo
            sx={{ width: "100%" }}
          />
        </TableCell>

        {/* <div className="selectable">
                    <Select
                        isClearable
                        // styles={this.customStyles}
                        onChange={this.props.changeHandler(index)}
                        options={itemsDB}
                    // defaultValue={this.showCustomer}
                    />
                </div> */}

        {/* <div><input name="description" type="text" value={description} onChange={this.props.changeHandler(index)} /></div> */}

        <TableCell style={{ width: "160px" }}>
          <input
            className="form-control h-100"
            name="quantity"
            key="quantity"
            type="number"
            step="1"
            style={{
              textAlign: "left",

              color: itemId?.item === returnMasterItem ? "red" : "black",
            }}
            // value={quantity}
            defaultValue={quantity}
            onBlur={this.props.changeHandler(index)}
            // onChange={this.props.changeHandler(index)}
            // onFocus={this.props.focusHandler}
          />
        </TableCell>

        {!hideLaborAndPurity && (
          <TableCell style={{ width: "160px" }}>
            {goldPurityType === "karat" ? (
              <MyAutocomplete
                //   style={{ width: "200px" }}
                name="purity"
                variant="standard"
                getOptionLabel={(option) => option?.karat + " KT"}
                options={goldPurityChart}
                value={purity}
                color={itemId?.item === returnMasterItem ? "red" : "black"}
                sx={{
                  width: 120,
                  color: itemId?.item === returnMasterItem ? "red" : "black",
                }}
                disableClearable
                onChange={(event, value) => {
                  event = {
                    target: {
                      name: "purity",
                      value: value,
                    },
                  };
                  this.props.changeHandler(index)(event);
                }}
                onFocus={this.props.focusHandler}
              />
            ) : (
              <input
                className="form-control"
                name="purity.percentage"
                type="number"
                step="1"
                min="0.00"
                style={{
                  textAlign: "left",
                  color: itemId?.item === returnMasterItem ? "red" : "black",
                }}
                max="99.99"
                // value={purity?.percentage}
                // onChange={this.props.changeHandler(index)}
                // onFocus={this.props.focusHandler}
                defaultValue={purity?.percentage}
                onBlur={this.props.changeHandler(index)}
              />
            )}
          </TableCell>
        )}

        {!hideLaborAndPurity && (
          <TableCell style={{ width: "160px" }}>
            <input
              className="form-control"
              name="labor"
              key="labor"
              type="number"
              step="1"
              min="0.00"
              max="9999999.99"
              style={{
                textAlign: "left",
                color: itemId?.item === returnMasterItem ? "red" : "black",
              }}
              disabled={!(purity?.percentage && quantity)}
              // value={labor}
              defaultValue={labor}
              onBlur={this.props.changeHandler(index)}
              // onChange={this.props.changeHandler(index)}
              // onFocus={this.props.focusHandler}
            />
          </TableCell>
        )}

        <TableCell>
          {/* <p style={{ margin: "auto" }}>{price}</p> */}
          <input
            className="form-control"
            name="price"
            type="number"
            step="0.01"
            min="0.00"
            max="9999999.99"
            value={price}
            style={{
              textAlign: "left",
              color: itemId?.item === returnMasterItem ? "red" : "black",
            }}
            disabled={
              hideLaborAndPurity ? !quantity : !(purity?.percentage && quantity)
            }
            // onChange={this.props.changeHandler(index)}
            defaultValue={price}
            onBlur={this.props.changeHandler(index)}
            // onFocus={this.props.focusHandler}
          />
        </TableCell>

        <TableCell
          style={{
            color: itemId?.item === returnMasterItem ? "red" : "black",
          }}
        >
          {amount}
        </TableCell>
        <TableCell>
          <button
            type="button"
            className={styles.deleteItem}
            onClick={this.props.deleteHandler(index)}
          >
            <MdOutlineCancel size="1.6em" />
          </button>
        </TableCell>
      </>
    );
  };
}

export default LineItem2;

LineItem2.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  itemId: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemsDB: PropTypes.object,
};
