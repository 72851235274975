import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdCancel as DeleteIcon } from 'react-icons/md'
import styles from './PaymentItem.module.scss'

// select
import CreatableSelect from 'react-select/creatable';

class PaymentItem extends Component {

  // handleChange = (e) => {
  //     console.log(e);
  // };

  render = () => {

    const { index, paymentMode, paymentTypeId, amount, paymentDate, paymentsMethod } = this.props

    // console.log(paymentTypeId);

    return (
      <div className={styles.lineItem}>
        <div>{index + 1}</div>
        {/* <div><input name="paymentMode" type="text" value={paymentMode} onChange={this.props.changeHandler(index)} /></div> */}
        {/* <div>
          <CreatableSelect
            isClearable
            onChange={this.handleChange}
            options={paymentsMethod}
          />
        </div> */}
        <div>
          <select className="w-100" name="paymentTypeId" value={paymentTypeId} onChange={this.props.changeHandler(index)}>
            <option selected>Select payment mode</option>
            {
              paymentsMethod.map((pm) => (
                <>
                  <option value={pm.id}>{pm.label}</option>
                </>
              ))
            }

          </select>
        </div>
        {/* <div><input name="description" type="text" value={description} onChange={this.props.changeHandler(index)} /></div> */}
        <div><input name="amount" type="number" step="1" value={amount} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        <div><input name="paymentDate" type="date" defaultValue={paymentDate} value={paymentDate} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        {/* <div className={styles.currency}>{this.props.currencyFormatter( quantity * price )}</div> */}
        <div>
          <button id="pay_delete_button" type="button"
            className={styles.deleteItem}
            onClick={this.props.deleteHandler(index)}
          ><DeleteIcon size="1.25em" /></button>
        </div>
      </div>
    )
  }
}

export default PaymentItem

PaymentItem.propTypes = {
  index: PropTypes.number.isRequired,
  paymentMode: PropTypes.string,
  paymentTypeId: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentDate: PropTypes.string,
  paymentsMethod: PropTypes.object
}


