import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MySvg } from "../JewelPay.svg";
import {
  faAngleLeft,
  faEnvelope,
  faPhone,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router";
import { Box, SvgIcon, Typography } from "@mui/material";
import { useAlert } from "react-alert";

export default () => {
  const history = useHistory();
  const { saveToken } = useToken();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const alert = useAlert();
  const handleInput = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/login`,
        loginData
      );

      if (res?.data) {
        saveToken(res?.data);
        window.location.reload();
      } else {
        throw { res };
      }
    } catch (err) {
      console.log(err);
      alert.error("Invalid username or password OR user logged in");
    }
  };
  return (
    <main>
      <section className="d-flex align-items-center justify-content-center mt-2">
        <Container className="d-block justify-content-center align-items-center">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  backgroundColor: "rgba(255, 255, 255, 0.31)",
                }}
                style={{
                  width: 525,
                  height: 60,
                  borderRadius: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/about")}
                >
                  ABOUT
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/pricing")}
                >
                  PRICING
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  onClick={() => history.push("/adm-reg")}
                >
                  SIGN UP
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    height: 40,
                    width: 120,
                  }}
                  //   onClick={() => history.push("/")}
                >
                  LOGIN
                </Button>
              </Box>
            </div>
            <Box
              sx={{
                width: 523,
                height: 600,
                backgroundColor: "rgba(255, 255, 255, 0.28)",
                display: "block",
                marginTop: "2px",
                paddingTop: 8,
                border: 2,
                borderColor: "#ffffff",
                borderRadius: 5,
              }}
              className="justify-content-center align-items-center"
            >
              <Form className="" onSubmit={handleLogin}>
                <Card
                  className="justify-content-center align-items-center"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.37)",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "5rem",
                    width: "70%",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      backgroundColor: "rgba(255, 255, 255, 0.37)",
                      borderRadius: 5,
                      marginTop: "1rem",
                      marginBottom: 2,
                      height: 80,
                      width: 300,
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <MySvg />
                      </div>
                      <div style={{ marginLeft: 2 }}>
                        <Typography
                          className="d-flex justify-content-center align-items-center pt-3 "
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Jewel Pay
                        </Typography>
                      </div>
                    </div>
                  </Box>

                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>PHONE / EMAIL</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        {/*<FontAwesomeIcon icon={faPhone} />*/}
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        onChange={handleInput}
                        name="email"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>PASSWORD</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          {/*<FontAwesomeIcon icon={faUnlockAlt} />*/}
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="password"
                          onChange={handleInput}
                          type="password"
                        />
                      </InputGroup>
                      <div className="text-center mt-3">
                        <span> Forgot Password?</span>
                      </div>
                    </Form.Group>
                  </Form.Group>
                </Card>
                <div className="text-center mt-3">
                  <div>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ width: 150, height: 50 }}
                    >
                      Login
                    </Button>
                  </div>
                  <div className="text-center mt-3">
                    <span>
                      Don't have account?
                      <Link to="/adm-reg">
                        <h6>&nbsp;sign up</h6>
                      </Link>
                    </span>
                  </div>
                </div>
              </Form>
            </Box>
          </Row>
        </Container>
      </section>
    </main>
  );
};
